import { inject, observer } from "mobx-react";
import * as React from "react";
import { Selector } from "../../Tools/MySelector";
// import { MyInput,Selector,MyFileInputRow } from "../../Tools";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { toJS } from "mobx";
import { Colors, invertDark, invertSolidDark } from "../../Config/colors.config";
import { MyFileInput } from "../../Tools/MyFileInput";
import { MyButton } from "../../Tools/MyButton";
import HelpIcon from '@mui/icons-material/Help';
import { MyInput } from "../../Tools/MyInput";
import {
  IconButton,
  Collapse,
  Box,
  Typography,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Checkbox from '@mui/material/Checkbox';




// import DownloadIcon from '@mui/icons-material/Download';
// import SelectLocationModal from "../../Components/SpecialOneTime/SelectLocationModal";
// import "../../config"

export const Page10 = inject("store")(
  observer((props) => {
    const { theme } = props.store.common;
    const { _handleChangeInputData, inputData, handlecheck, inputDataSec,  _handleChangeInputDataSec} = props.store.timeseries;
    // const { setInputs, inputs } = props.store.building;
    // const { _handleChangeInputData, preliminaryData } =
    //   props.store.newTargetSetting;

   const tempData = ["Add New Figure","Add New Figure"]



    return (
      <div className="mx-3 my-3" style={{color: invertSolidDark(theme),}}>
        { tempData.map( (v,i) => {
         return (
        <>
        <div
          className="d-flex px-3 py-3 w-100 mb-3"
          style={{
            backgroundColor: "#38B67A",
            borderRadius: 5,
            marginLeft: "5px",
            color: invertSolidDark(theme)
          }}
        >
          <label style={{ color: "#fff"}}>{v}</label>
          <div
            className="d-flex align-items-center bg-light justify-content-center ms-auto"
            style={{ borderRadius: "50%", width: 25, height: 25 }}
            onClick={() => handlecheck(inputData[`isOpen${i}`] ? inputData[`isOpen${i}`] : false, `isOpen${i}`)}>
            <IconButton
              style={{}}
              aria-label="expand row"
              size="small"
            // onClick={()=>handlecheck(inputData.isOpen ? inputData.isOpen : false, "isOpen")}
            >
              {!inputData[`isOpen${i}`] ? (
                <AddIcon
                  style={{ color: "green", fontWeight: "bold" }}
                />
              ) : (
                <RemoveIcon
                  style={{ color: "green", fontWeight: "bold" }}
                />
              )}
            </IconButton>
          </div>

        </div>
        <Collapse in={inputData[`isOpen${i}`]}>
          <div className="col-12 py-2" style={{}}>

            <div className="col-xl-4 col-lg-4 col-md-4 col-4 d-flex" style={{ justifyContent: 'space-between' }}>
              <label style={{marginTop: "12px", marginBottom: '5px', paddingLeft: 20}}>Title</label>
              <MyInput
                width="250px"
                background="#fff"
                color="#000"
                type="text"
                value={inputDataSec[i]?.title || ''} 
                onChange={(e) => _handleChangeInputDataSec(i, e.target.value, "title")}
              />
            </div>

            <div className="col-12">
         
              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="col-lg-4 col-4">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px"}}
                    >
                      Installed Capacities for Every Type of Assets
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-8" style={{}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                            textAlign:'center'
                          }}
                        >
                          <span className="">Curve</span>
                          <Checkbox
                            checked={inputDataSec[i]?.assectCapacityCurve ? inputDataSec[i]?.assectCapacityCurve : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.assectCapacityCurve ? inputDataSec[i]?.assectCapacityCurve : false, "assectCapacityCurve")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                            textAlign:'center'
                          }}
                        >
                          <span className="" style={{  }} >Charts</span>
                          <Checkbox
                            checked={inputDataSec[i]?.assectCapacityCharts ? inputDataSec[i]?.assectCapacityCharts : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.assectCapacityCharts ? inputDataSec[i]?.assectCapacityCharts : false, "assectCapacityCharts")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                            textAlign:'center'
                          }}
                        >
                          <span className="" style={{  }} >Add to chart benchmark</span>
                          <Checkbox
                           checked={inputDataSec[i]?.assectCapacityChartBench ? inputDataSec[i]?.assectCapacityChartBench : false}
                           onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.assectCapacityChartBench ? inputDataSec[i]?.assectCapacityChartBench : false, "assectCapacityChartBench")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                            textAlign:'center'
                          }}
                        >
                          <span className="" style={{  }} >Add to polygon benchmark</span>
                          <Checkbox
                           checked={inputDataSec[i]?.assectCapacityChartPoly ? inputDataSec[i]?.assectCapacityChartPoly : false}
                           onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.assectCapacityChartPoly ? inputDataSec[i]?.assectCapacityChartPoly : false, "assectCapacityChartPoly")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                   

                  </div>
                </div>
              </div>

              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="col-lg-4 col-4">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px", }}
                    >
                      Generated Energy for Every Assets
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-8" style={{}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                  
                          <Checkbox
                            checked={inputDataSec[i]?.generateAssetCurve ? inputDataSec[i]?.generateAssetCurve : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.generateAssetCurve ? inputDataSec[i]?.generateAssetCurve : false, "generateAssetCurve")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputDataSec[i]?.generateAssetChart ? inputDataSec[i]?.generateAssetChart : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.generateAssetChart ? inputDataSec[i]?.generateAssetChart : false, "generateAssetChart")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          
                          <Checkbox
                            checked={inputDataSec[i]?.generateAssetChartBench ? inputDataSec[i]?.generateAssetChartBench : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.generateAssetChartBench ? inputDataSec[i]?.generateAssetChartBench : false, "generateAssetChartBench")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                           checked={inputDataSec[i]?.generateAssetChartPoly ? inputDataSec[i]?.generateAssetChartPoly : false}
                           onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.generateAssetChartPoly ? inputDataSec[i]?.generateAssetChartPoly : false, "generateAssetChartPoly")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                   

                  </div>
                </div>
              </div>

              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="col-lg-4 col-4">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px", }}
                    >
                      Consumed Energy for Every Assets
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-8" style={{}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                  
                          <Checkbox
                            checked={inputDataSec[i]?.consumedAssetCurve ? inputDataSec[i]?.consumedAssetCurve : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.consumedAssetCurve ? inputDataSec[i]?.consumedAssetCurve : false, "consumedAssetCurve")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                             checked={inputDataSec[i]?.consumedAssetChart ? inputDataSec[i]?.consumedAssetChart : false}
                             onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.consumedAssetChart ? inputDataSec[i]?.consumedAssetChart : false, "consumedAssetChart")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          
                          <Checkbox
                            checked={inputDataSec[i]?.consumedAssetChartBench ? inputDataSec[i]?.consumedAssetChartBench : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.consumedAssetChartBench ? inputDataSec[i]?.consumedAssetChartBench : false, "consumedAssetChartBench")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputDataSec[i]?.consumedAssetPolyBench ? inputDataSec[i]?.consumedAssetPolyBench : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.consumedAssetPolyBench ? inputDataSec[i]?.consumedAssetPolyBench : false, "consumedAssetPolyBench")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                   

                  </div>
                </div>
              </div>

              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="col-lg-4 col-4">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px", }}
                    >
                      OPEX:
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-8" style={{}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                  
                          <Checkbox
                            checked={inputDataSec[i]?.OPEXCurve ? inputDataSec[i]?.OPEXCurve : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.OPEXCurve ? inputDataSec[i]?.OPEXCurve : false, "OPEXCurve")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputDataSec[i]?.OPEXChart ? inputDataSec[i]?.OPEXChart : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.OPEXChart ? inputDataSec[i]?.OPEXChart : false, "OPEXChart")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {/* <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          
                          <Checkbox
                            checked={inputData.electPriceStandard ? inputData.electPriceStandard : false}
                            onChange={() => handlecheck(inputData.electPriceStandard ? inputData.electPriceStandard : false, "electPriceStandard")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div> */}

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {/* <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputData.electPriceStandard ? inputData.electPriceStandard : false}
                            onChange={() => handlecheck(inputData.electPriceStandard ? inputData.electPriceStandard : false, "electPriceStandard")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div> */}

                    </div>
                   

                  </div>
                </div>
              </div>

              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="col-lg-4 col-4">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px", }}
                    >
                      CAPEX
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-8" style={{}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                  
                          <Checkbox
                            checked={inputDataSec[i]?.CAPEXCurve ? inputDataSec[i]?.CAPEXCurve : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.CAPEXCurve ? inputDataSec[i]?.CAPEXCurve : false, "CAPEXCurve")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputDataSec[i]?.CAPEXChart ? inputDataSec[i]?.CAPEXChart : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.CAPEXChart ? inputDataSec[i]?.CAPEXChart : false, "CAPEXChart")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          
                          <Checkbox
                            checked={inputDataSec[i]?.CAPEXChartBench ? inputDataSec[i]?.CAPEXChartBench : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.CAPEXChartBench ? inputDataSec[i]?.CAPEXChartBench : false, "CAPEXChartBench")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {/* <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputData.electPriceStandard ? inputData.electPriceStandard : false}
                            onChange={() => handlecheck(inputData.electPriceStandard ? inputData.electPriceStandard : false, "electPriceStandard")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div> */}

                    </div>
                   

                  </div>
                </div>
              </div>

              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="col-lg-4 col-4">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px", }}
                    >
                     NPV
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-8" style={{}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          {/* <span className="" style={{  }} >Curve</span> */}
                          <Checkbox
                            checked={inputDataSec[i]?.NPVCurve ? inputDataSec[i]?.NPVCurve : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.NPVCurve ? inputDataSec[i]?.NPVCurve : false, "NPVCurve")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          {/* <span className="" style={{  }} >Charts</span> */}
                          <Checkbox
                            checked={inputDataSec[i]?.NPVChart ? inputDataSec[i]?.NPVChart : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.NPVChart ? inputDataSec[i]?.NPVChart : false, "NPVChart")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          {/* <span className="" style={{  }} >Add to chart benchmark</span> */}
                          <Checkbox
                            checked={inputDataSec[i]?.NPVChartBench ? inputDataSec[i]?.NPVChartBench : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.NPVChartBench ? inputDataSec[i]?.NPVChartBench : false, "NPVChartBench")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          {/* <span className="" style={{  }} >Add to polygon benchmark</span> */}
                          <Checkbox
                            checked={inputDataSec[i]?.NPVPolyBench ? inputDataSec[i]?.NPVPolyBench : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.NPVPolyBench ? inputDataSec[i]?.NPVPolyBench : false, "NPVPolyBench")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                   

                  </div>
                </div>
              </div>

              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="col-lg-4 col-4">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px", }}
                    >
                     Maintenance
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-8" style={{}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                  
                          <Checkbox
                            checked={inputDataSec[i]?.maintenanceCurve ? inputDataSec[i]?.maintenanceCurve : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.maintenanceCurve ? inputDataSec[i]?.maintenanceCurve : false, "maintenanceCurve")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputDataSec[i]?.maintenanceChart ? inputDataSec[i]?.maintenanceChart : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.maintenanceChart ? inputDataSec[i]?.maintenanceChart : false, "maintenanceChart")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          
                          <Checkbox
                            checked={inputDataSec[i]?.maintenanceChartBench ? inputDataSec[i]?.maintenanceChartBench : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.maintenanceChartBench ? inputDataSec[i]?.maintenanceChartBench : false, "maintenanceChartBench")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputDataSec[i]?.maintenancePolyBench ? inputDataSec[i]?.maintenancePolyBench : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.maintenancePolyBench ? inputDataSec[i]?.maintenancePolyBench : false, "maintenancePolyBench")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                   

                  </div>
                </div>
              </div>

              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="col-lg-4 col-4">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px", }}
                    >
                      Consumed Fuel
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-8" style={{}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                  
                          <Checkbox
                            checked={inputDataSec[i]?.consumFuelCurve ? inputDataSec[i]?.consumFuelCurve : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.consumFuelCurve ? inputDataSec[i]?.consumFuelCurve : false, "consumFuelCurve")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputDataSec[i]?.consumFuelChart ? inputDataSec[i]?.consumFuelChart : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.consumFuelChart ? inputDataSec[i]?.consumFuelChart : false, "consumFuelChart")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          
                          <Checkbox
                            checked={inputDataSec[i]?.consumFuelChartBench ? inputDataSec[i]?.consumFuelChartBench : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.consumFuelChartBench ? inputDataSec[i]?.consumFuelChartBench : false, "consumFuelChartBench")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputDataSec[i]?.consumFuelPolyBench ? inputDataSec[i]?.consumFuelPolyBench : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.consumFuelPolyBench ? inputDataSec[i]?.consumFuelPolyBench : false, "consumFuelPolyBench")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                   

                  </div>
                </div>
              </div>

              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="col-lg-4 col-4">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px", }}
                    >
                      Add to Benchmark
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-8" style={{}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                  
                          <Checkbox
                            checked={inputDataSec[i]?.addBenchCurve ? inputDataSec[i]?.addBenchCurve : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.addBenchCurve ? inputDataSec[i]?.addBenchCurve : false, "addBenchCurve")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputDataSec[i]?.addBenchChart ? inputDataSec[i]?.addBenchChart : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.addBenchChart ? inputDataSec[i]?.addBenchChart : false, "addBenchChart")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          
                          <Checkbox
                            checked={inputDataSec[i]?.addBenchChartBench ? inputDataSec[i]?.addBenchChartBench : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.addBenchChartBench ? inputDataSec[i]?.addBenchChartBench : false, "addBenchChartBench")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputDataSec[i]?.addBenchPolyBench ? inputDataSec[i]?.addBenchPolyBench : false}
                            onChange={() => _handleChangeInputDataSec(i,inputDataSec[i]?.addBenchPolyBench ? inputDataSec[i]?.addBenchPolyBench : false, "addBenchPolyBench")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                   

                  </div>
                </div>
              </div>

            </div>
          </div>


        </Collapse>
        </>
         )

        })

        }
        
      </div>
      
    );
  })
);
