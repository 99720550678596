import { inject, observer } from "mobx-react";
import * as React from "react";
import { Colors, invertDark, invertSolidDark } from "../../Config/colors.config";
import { MyInput } from "../../Tools/MyInput";
import {
  IconButton,
  Collapse,
  Box,
  Typography,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Checkbox from '@mui/material/Checkbox';

export const Page11 = inject("store")(
  observer((props) => {
    const { theme } = props.store.common;
    const { _handleChangeInputData, inputData, handlecheck,_handleChangeInputDataSec,inputDataSec } = props.store.timeseries;

   const tempData = ["Add New Figure"]



    return (
      <div className="mx-3 my-3">
        { tempData.map( (v,i) => {
         return (
<>
        <div
          className="d-flex px-3 py-3 w-100 mb-3"
          style={{
            backgroundColor: "#38B67A",
            borderRadius: 5,
            marginLeft: "5px",
          }}
        >
          <label style={{ color: "#fff"}}>{v}</label>
          <div
            className="d-flex align-items-center bg-light justify-content-center ms-auto"
            style={{ borderRadius: "50%", width: 25, height: 25 }}
            onClick={() => handlecheck(inputData.isOpen ? inputData.isOpen : false, "isOpen")}>
            <IconButton
              style={{}}
              aria-label="expand row"
              size="small"
            // onClick={()=>handlecheck(inputData.isOpen ? inputData.isOpen : false, "isOpen")}
            >
              {!inputData?.isOpen ? (
                <AddIcon
                  style={{ color: "green", fontWeight: "bold" }}
                />
              ) : (
                <RemoveIcon
                  style={{ color: "green", fontWeight: "bold" }}
                />
              )}
            </IconButton>
          </div>

        </div>
        <Collapse in={inputData.isOpen}>
          <div className="col-12 py-2" style={{}}>

            <div className="col-xl-4 col-lg-4 col-md-4 col-4 d-flex" style={{ justifyContent: 'space-between' }}>
              <label style={{marginTop: "12px", color: invertSolidDark(theme), marginBottom: '5px', paddingLeft: 20}}>Title</label>
              <MyInput
                width="250px"
                background="#fff"
                color="#000"
                type="text"
                value={inputDataSec[i]?.assetTitle || ''} // Set an empty string if inputData?.capacity is undefined
                onChange={(e) => _handleChangeInputDataSec(i,e.target.value, "assetTitle")}
              />
            </div>

            <div className="col-12">
         
              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-3 col-3">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px" }}
                    >
                      Genset Elec/Gas
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-9" style={{color:invertSolidDark(theme)}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                            textAlign:'center'
                          }}
                        >
                          <span className="" style={{}} >Optimized solution scenario</span>
                          <Checkbox
                            checked={inputDataSec[i]?.gensetSolution ? inputDataSec[i]?.gensetSolution : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.gensetSolution ? inputDataSec[i]?.gensetSolution : false, "gensetSolution")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <span className="" style={{ }} ></span>
                          <Checkbox
                            checked={inputDataSec[i]?.genset ? inputDataSec[i]?.genset : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.genset ? inputDataSec[i]?.genset : false, "genset")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-3 col-3">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px" }}
                    >
                      Battery
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-9" style={{}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                  
                          <Checkbox
                             checked={inputDataSec[i]?.batterySolution ? inputDataSec[i]?.batterySolution : false}
                             onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.batterySolution ? inputDataSec[i]?.batterySolution : false, "batterySolution")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputDataSec[i]?.battery ? inputDataSec[i]?.battery : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.battery ? inputDataSec[i]?.battery : false, "battery")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-3 col-3">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px" }}
                    >
                      Thermal Storage
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-9" style={{}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                  
                          <Checkbox
                            checked={inputDataSec[i]?.thermalStorageSolution ? inputDataSec[i]?.thermalStorageSolution : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.thermalStorageSolution ? inputDataSec[i]?.thermalStorageSolution : false, "thermalStorageSolution")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputDataSec[i]?.thermalStorage ? inputDataSec[i]?.thermalStorage : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.thermalStorage ? inputDataSec[i]?.thermalStorage : false, "thermalStorage")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-3 col-3">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px" }}
                    >
                      CHP Elec/Heat
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-9" style={{}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                  
                          <Checkbox
                            checked={inputDataSec[i]?.CHPSolution ? inputDataSec[i]?.CHPSolution : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.CHPSolution ? inputDataSec[i]?.CHPSolution : false, "CHPSolution")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputDataSec[i]?.CHP ? inputDataSec[i]?.CHP : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.CHP ? inputDataSec[i]?.CHP : false, "CHP")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-3 col-3">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px" }}
                    >
                      Vapour Chiller Elec.Consumption
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-9" style={{}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                  
                          <Checkbox
                            checked={inputDataSec[i]?.VapourChillerSolution ? inputDataSec[i]?.VapourChillerSolution : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.VapourChillerSolution ? inputDataSec[i]?.VapourChillerSolution : false, "VapourChillerSolution")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputDataSec[i]?.VapourChiller ? inputDataSec[i]?.VapourChiller : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.VapourChiller ? inputDataSec[i]?.VapourChiller : false, "VapourChiller")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </Collapse>
        </>
         )

        })

        }
        
      </div>
      
    );
  })
);
