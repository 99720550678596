export const Colors = {
    light: {
      darkGreenBg:"#073B3A",
      darkSoftGreenBg:"#055F31",
      softGreenBg:"#38B67A"
    },
    dark: {
      primaryColor: "#C6C6C6",
      secondaryColor: "#5E5E5E",
      background: "linear-gradient(65deg,#101841,#202b60)",
      newBackground: "rgb(23 31 95)",
      textColor: "#c6c6c6",
      active: "#202b60",
      tablebody:"#202b60",
      activeTimeline: "#2f528f",
      noActiveTimeline: "#ffffff",
      secondBackground: "#182149",
      tableBackground:"#182149",
      navBackground: "#202b60",
      borderColor: "#202b60",
      secBorderColor: "#696969",
      glass: "rgb(255,255,255,0.06)",
      headerBgColor: "linear-gradient(180deg,#010849,#1d256c)",
      newHeaderBgColor: "rgb(16 26 68)",
      tableBgColor: "#000534",
      backgrounColor:"#010849",
      greyColor:"#717171",
      buttonColor:"#2D3C86",
      sidebarColor:"#d8e2f3",
      darkGreenBg:"#073B3A",
      darkSoftGreenBg:"#055F31",
      softGreenBg:"#38B67A"
    },
    Dark: ["#000000", "#303030", "#5E5E5E", "#919191", "#C6C6C6", "#F6F6F6","#6c7783","#5161B6","#5A70E2"],
    Chart_Color: [`#f44336`, `#e91e63`, `#9c27b0`, `#673ab7`, `#2196f3`, `#009688`, `#ffc107`, `#ff5722`, `#5D4037`],
    // fuel: "#FF3400",
    // fuelLight: "#f4b183",
    // fuelBaseline: "red",
    // medium: "#FFD106",
    low: "#FF0000",
    // low: "#00B100",
    tetradic:[ "#202b60","#60204B","#206035","#605520"],
    triadic:["#202B60","#60202B","#2B6020"],
    mono:["#202b60","#2D3C86"],
    energy: "#ff5c00",
    orange: "#CA4A02",
    water: "#2982ff",
    blue: '#0056D2',
    white: '#ffffff',
    greenMaterial: "#04c500",
    darkBlue: "#182149",
    primaryBule: "#202b60",
    lightBlue: "#2D3C86",
    buttonColor: "linear-gradient(45deg,#202b60,#2D3C86)",
    buttonHoverColor: "linear-gradient(#2D3C86,#182149)",
    // activeColor: "#919191"
    shadow: "rgb(0 0 0 / 30%) 2px 3px 10px",
    login_shadow:"rgb(0 0 0 / 100%) 2px 3px 10px"
  };
  
  export const invertDark = (theme) => {
    return (theme === "dark" ? "#C6C6C6" : "#303030")
  };

  export const invertSolidDark =(theme)=>{
    return (theme === "dark" ? "#fff" : "#000")
  }