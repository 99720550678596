import * as React from "react";
import { useState } from "react";
import { inject, observer } from "mobx-react";
import { Colors, invertDark, invertSolidDark } from "../../Config/colors.config";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useEffect } from "react";
import {
    IconButton,
    Collapse,
    Box,
    Typography,
  } from "@mui/material";
  import { styled } from "@mui/material/styles";
  import HelpIcon from '@mui/icons-material/Help';

export const Page9 = inject("store")(
  observer((props) => {
    const { theme } = props.store.common;
    const {inputData,_handleChangeInputData} =props.store.timeseries;


    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip
          {...props}
          classes={{ popper: className }}
          placement="right-start"
          arrow
        />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: "#f5f5f9",
          color: "rgba(0, 0, 0, 0.87)",
          minWidth: 380,
          fontSize: theme.typography.pxToRem(25),
          border: "1px solid #dadde9",
        },
        [`& .${tooltipClasses.arrow}`]: {
          color: "#f5f5f9",
        },
      }));
    const tempData = [
      { value: 1, label: "Every 15 minutes" },
      { value: 2, label: "Every 30 minutes" },
      { value: 3, label: "Hourly" },
      { value: 4, label: "Daily" },
      { value: 5, label: "Weekly" },
      { value: 6, label: "Monthly" },
    ];
    const consumptionData = [
      { value: 1, label: "Electricity" },
      { value: 2, label: "Natural Gas" },
      { value: 3, label: "Liquified Petroleum Gas (LPG)" },
      { value: 4, label: "Purchased Cooling" },
      { value: 5, label: "Purchased Heating" },
      { value: 6, label: "Purchased Water" },
    ]
    const unitData = [
      { value: 1, label: "kWh" },
      { value: 2, label: "mmBtu" },
      { value: 3, label: "Refrigeration Ton (RT)" },
      { value: 4, label: "kg" },
      { value: 5, label: "Litres" },

    ]
    const numeratorData = [
      { value: 1, label: "10" },
      { value: 2, label: "20" },
      { value: 3, label: "30" },
      { value: 4, label: "40" },
      { value: 5, label: "50" },
    ]
    const facilityDocuments = [
      { value: 1, label: "Floor Plans" },
      { value: 2, label: "Single Line Diagrams" },
      { value: 3, label: "Chilled Water Piping Schematics" },
      { value: 4, label: "Potable Water Piping Schematics" },
    ]
    
    
    useEffect(()=>{
      console.log("use effect")
      var rangeInputCapex = document.getElementById("custom_Range_Capex");
      var percentageLabelCapex = document.getElementById("percentageLabelCapex");
  
      var rangeInputOpex = document.getElementById("custom_Range_Opex");
      var percentageLabelOpex = document.getElementById("percentageLabelOpex");
  
      var rangeInputNPV = document.getElementById("custom_Range_NPV");
      var percentageLabelNPV = document.getElementById("percentageLabelNPV");
  
      var rangeInputGHG = document.getElementById("custom_Range_GHG");
      var percentageLabelGHG = document.getElementById("percentageLabelGHG");
  
      var rangeInputElectrical = document.getElementById("custom_Range_Electrical");
      var percentageLabelElectrical = document.getElementById("percentageLabelElectrical");
  
      var rangeInputCooling = document.getElementById("custom_Range_Cooling");
      var percentageLabelCooling = document.getElementById("percentageLabelCooling");
  
      // Update the background color based on the slider position
      rangeInputCapex?.addEventListener("input", function() {
        var percentage = (rangeInputCapex.value - rangeInputCapex.min) / (rangeInputCapex.max - rangeInputCapex.min) * 100;
        rangeInputCapex.style.background = 'linear-gradient(to right, #38B67A 0%, #38B67A ' + percentage + '%, #DDDDDD ' + percentage + '%, #DDDDDD 100%)';
        percentageLabelCapex.innerText = Math.round(percentage) + '%';
      });

      var percentage = (rangeInputCapex.value - rangeInputCapex.min) / (rangeInputCapex.max - rangeInputCapex.min) * 100;
        rangeInputCapex.style.background = 'linear-gradient(to right, #38B67A 0%, #38B67A ' + percentage + '%, #DDDDDD ' + percentage + '%, #DDDDDD 100%)';
        percentageLabelCapex.innerText = Math.round(percentage) + '%';
  
      rangeInputOpex?.addEventListener("input", function() {
          var percentage = (rangeInputOpex.value - rangeInputOpex.min) / (rangeInputOpex.max - rangeInputOpex.min) * 100;
          rangeInputOpex.style.background = 'linear-gradient(to right, #38B67A 0%, #38B67A ' + percentage + '%, #DDDDDD ' + percentage + '%, #DDDDDD 100%)';
          percentageLabelOpex.innerText = Math.round(percentage) + '%';
        });

        var percentage = (rangeInputOpex.value - rangeInputOpex.min) / (rangeInputOpex.max - rangeInputOpex.min) * 100;
          rangeInputOpex.style.background = 'linear-gradient(to right, #38B67A 0%, #38B67A ' + percentage + '%, #DDDDDD ' + percentage + '%, #DDDDDD 100%)';
          percentageLabelOpex.innerText = Math.round(percentage) + '%';
  
        rangeInputNPV?.addEventListener("input", function() {
          var percentage = (rangeInputNPV.value - rangeInputNPV.min) / (rangeInputNPV.max - rangeInputNPV.min) * 100;
          rangeInputNPV.style.background = 'linear-gradient(to right, #38B67A 0%, #38B67A ' + percentage + '%, #DDDDDD ' + percentage + '%, #DDDDDD 100%)';
          percentageLabelNPV.innerText = Math.round(percentage) + '%';
        });

        var percentage = (rangeInputNPV.value - rangeInputNPV.min) / (rangeInputNPV.max - rangeInputNPV.min) * 100;
          rangeInputNPV.style.background = 'linear-gradient(to right, #38B67A 0%, #38B67A ' + percentage + '%, #DDDDDD ' + percentage + '%, #DDDDDD 100%)';
          percentageLabelNPV.innerText = Math.round(percentage) + '%';
  
        rangeInputGHG?.addEventListener("input", function() {
          var percentage = (rangeInputGHG.value - rangeInputGHG.min) / (rangeInputGHG.max - rangeInputGHG.min) * 100;
          rangeInputGHG.style.background = 'linear-gradient(to right, #38B67A 0%, #38B67A ' + percentage + '%, #DDDDDD ' + percentage + '%, #DDDDDD 100%)';
          percentageLabelGHG.innerText = Math.round(percentage) + '%';
        });

        var percentage = (rangeInputGHG.value - rangeInputGHG.min) / (rangeInputGHG.max - rangeInputGHG.min) * 100;
          rangeInputGHG.style.background = 'linear-gradient(to right, #38B67A 0%, #38B67A ' + percentage + '%, #DDDDDD ' + percentage + '%, #DDDDDD 100%)';
          percentageLabelGHG.innerText = Math.round(percentage) + '%';
  
        rangeInputElectrical?.addEventListener("input", function() {
          var percentage = (rangeInputElectrical.value - rangeInputElectrical.min) / (rangeInputElectrical.max - rangeInputElectrical.min) * 100;
          rangeInputElectrical.style.background = 'linear-gradient(to right, #38B67A 0%, #38B67A ' + percentage + '%, #DDDDDD ' + percentage + '%, #DDDDDD 100%)';
          percentageLabelElectrical.innerText = Math.round(percentage) + '%';
        });

        var percentage = (rangeInputElectrical.value - rangeInputElectrical.min) / (rangeInputElectrical.max - rangeInputElectrical.min) * 100;
          rangeInputElectrical.style.background = 'linear-gradient(to right, #38B67A 0%, #38B67A ' + percentage + '%, #DDDDDD ' + percentage + '%, #DDDDDD 100%)';
          percentageLabelElectrical.innerText = Math.round(percentage) + '%';
  
        rangeInputCooling?.addEventListener("input", function() {
          var percentage = (rangeInputCooling.value - rangeInputCooling.min) / (rangeInputCooling.max - rangeInputCooling.min) * 100;
          rangeInputCooling.style.background = 'linear-gradient(to right, #38B67A 0%, #38B67A ' + percentage + '%, #DDDDDD ' + percentage + '%, #DDDDDD 100%)';
          percentageLabelCooling.innerText = Math.round(percentage) + '%';
        });

        var percentage = (rangeInputCooling.value - rangeInputCooling.min) / (rangeInputCooling.max - rangeInputCooling.min) * 100;
          rangeInputCooling.style.background = 'linear-gradient(to right, #38B67A 0%, #38B67A ' + percentage + '%, #DDDDDD ' + percentage + '%, #DDDDDD 100%)';
          percentageLabelCooling.innerText = Math.round(percentage) + '%';

    },[inputData])
    

    



    return (
      <div>
        <div
          className=""
          style={{
            // background: theme == "light" ? Colors.light.active : "#010849",
            // borderRadius: "15px",
            // width: "100%",
            marginLeft: "5px",
            // marginTop: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="col-lg-12 col-12" style={{}}>
              <div
                className=""
                style={{ display: "flex", alignItems: "center",justifyContent:'space-between',margin:'40px 0px',color:invertSolidDark(theme) }}
              >
                <div className="col-lg-1 col-1">
                </div>
                <div className="col-lg-4 col-4">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ }}
                    >
                      Objective Weight of CAPEX <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography fontSize={14}>
                          {"Objective Weight of CAPEX"}
                        </Typography> 
                      </React.Fragment>
                    }>
                    <HelpIcon
                    //   onClick={(e) => e.stopPropagation()}
                      style={{
                        fill: theme === "dark" ? "#ddd" : "#1565c0",
                        marginLeft: "8px",
                      }}
                    />
                  </HtmlTooltip>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-6" style={{display:'flex',alignItems:'center',gap:'5px'}}>
                    {/* <MySlider
                    theme={theme}
                    min={0}
                    max={100}
                    id="custom_Range_capex"
                    labelId="percentageLabelCapex"
                    value={range}
                    onChange={(e)=>handleRange(e)}
                    /> */}
                    <input type="range" id="custom_Range_Capex" name="vol" min="0" value={inputData?.CAPEXRange ? inputData?.CAPEXRange : 0} max="100" onChange={(e)=>_handleChangeInputData(e.target.value,"CAPEXRange")}></input>
                    <span class="percentage-label" id="percentageLabelCapex" style={{color:invertSolidDark(theme)}}>0%</span>
                </div>
                <div className="col-lg-1 col-1">

                </div>
              </div>
              <div
                className=""
                style={{ display: "flex", alignItems: "center",justifyContent:'space-between',margin:'40px 0px',color:invertSolidDark(theme) }}
              >
                <div className="col-lg-1 col-1">
                </div>
                <div className="col-lg-4 col-4">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ }}
                    >
                      Objective Weight of OPEX <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography fontSize={14}>
                          {"Objective Weight of OPEX"}
                        </Typography> 
                      </React.Fragment>
                    }>
                    <HelpIcon
                    //   onClick={(e) => e.stopPropagation()}
                      style={{
                        fill: theme === "dark" ? "#ddd" : "#1565c0",
                        marginLeft: "8px",
                      }}
                    />
                  </HtmlTooltip>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-6" style={{display:'flex',alignItems:'center',gap:'5px'}}>
                    <input type="range" id="custom_Range_Opex" name="vol" min="0" value={inputData?.OPEXRange ? inputData?.OPEXRange : 0} max="100" onChange={(e)=>_handleChangeInputData(e.target.value,"OPEXRange")}></input>
                    <span class="percentage-label" id="percentageLabelOpex" style={{color:invertSolidDark(theme)}}>0%</span>
                </div>
                <div className="col-lg-1 col-1">

                </div>
              </div>
              <div
                className=""
                style={{ display: "flex", alignItems: "center",justifyContent:'space-between',margin:'40px 0px',color:invertSolidDark(theme) }}
              >
                <div className="col-lg-1 col-1">
                </div>
                <div className="col-lg-4 col-4">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ }}
                    >
                      Objective Weight of NPV <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography fontSize={14}>
                          {"Objective Weight of NPV"}
                        </Typography> 
                      </React.Fragment>
                    }>
                    <HelpIcon
                    //   onClick={(e) => e.stopPropagation()}
                      style={{
                        fill: theme === "dark" ? "#ddd" : "#1565c0",
                        marginLeft: "8px",
                      }}
                    />
                  </HtmlTooltip>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-6" style={{display:'flex',alignItems:'center',gap:'5px'}}>
                <input type="range" id="custom_Range_NPV" name="vol" min="0" value={inputData?.NPVRange ? inputData?.NPVRange : 0} max="100" onChange={(e)=>_handleChangeInputData(e.target.value,"NPVRange")}></input>
                    <span class="percentage-label" id="percentageLabelNPV" style={{color:invertSolidDark(theme)}}>0%</span>
                </div>
                <div className="col-lg-1 col-1">

                </div>
              </div>
              <div
                className=""
                style={{ display: "flex", alignItems: "center",justifyContent:'space-between',margin:'40px 0px',color:invertSolidDark(theme) }}
              >
                <div className="col-lg-1 col-1">
                </div>
                <div className="col-lg-4 col-4">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ }}
                    >
                      Objective Weight of GHG Emission <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography fontSize={14}>
                          {"Objective Weight of GHG Emission"}
                        </Typography> 
                      </React.Fragment>
                    }>
                    <HelpIcon
                    //   onClick={(e) => e.stopPropagation()}
                      style={{
                        fill: theme === "dark" ? "#ddd" : "#1565c0",
                        marginLeft: "8px",
                      }}
                    />
                  </HtmlTooltip>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-6" style={{display:'flex',alignItems:'center',gap:'5px'}}>
                    <input type="range" id="custom_Range_GHG" name="vol" min="0" value={inputData?.GHGRange ? inputData?.GHGRange : 0} max="100" onChange={(e)=>_handleChangeInputData(e.target.value,"GHGRange")}></input>
                    <span class="percentage-label" id="percentageLabelGHG" style={{color:invertSolidDark(theme)}}>0%</span>
                </div>
                <div className="col-lg-1 col-1">

                </div>
              </div>
              <hr style={{margin:'40px 0px',color:invertDark(theme)}} />
              <div
                className=""
                style={{ display: "flex", alignItems: "center",justifyContent:'space-between',margin:'40px 0px',color:invertSolidDark(theme) }}
              >
                <div className="col-lg-1 col-1">
                </div>
                <div className="col-lg-4 col-4">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ }}
                    >
                      Scaling Electrical Load <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography fontSize={14}>
                          {"Scaling Electrical Load"}
                        </Typography> 
                      </React.Fragment>
                    }>
                    <HelpIcon
                    //   onClick={(e) => e.stopPropagation()}
                      style={{
                        fill: theme === "dark" ? "#ddd" : "#1565c0",
                        marginLeft: "8px",
                      }}
                    />
                  </HtmlTooltip>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-6" style={{display:'flex',alignItems:'center',gap:'5px'}}>
                    <input type="range" id="custom_Range_Electrical" name="vol" min="0" value={inputData?.electricalRange ? inputData?.electricalRange : 0} max="100" onChange={(e)=>_handleChangeInputData(e.target.value,"electricalRange")}></input>
                    <span class="percentage-label" id="percentageLabelElectrical" style={{color:invertSolidDark(theme)}}>0%</span>
                </div>
                <div className="col-lg-1 col-1">

                </div>
              </div>
              
              <div
                className=""
                style={{ display: "flex", alignItems: "center",justifyContent:'space-between',margin:'40px 0px',color:invertSolidDark(theme) }}
              >
                <div className="col-lg-1 col-1">
                </div>
                <div className="col-lg-4 col-4">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ }}
                    >
                      Scaling Cooling Load <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography fontSize={14}>
                          {"Scaling Cooling Load"}
                        </Typography> 
                      </React.Fragment>
                    }>
                    <HelpIcon
                    //   onClick={(e) => e.stopPropagation()}
                      style={{
                        fill: theme === "dark" ? "#ddd" : "#1565c0",
                        marginLeft: "8px",
                      }}
                    />
                  </HtmlTooltip>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-6" style={{display:'flex',alignItems:'center',gap:'5px'}}>
                    <input type="range" id="custom_Range_Cooling" name="vol" min="0" value={inputData?.coolingLoadRange ? inputData?.coolingLoadRange : 0} max="100" onChange={(e)=>_handleChangeInputData(e.target.value,"coolingLoadRange")}></input>
                    <span class="percentage-label" id="percentageLabelCooling" style={{color:invertSolidDark(theme)}}>0%</span>
                </div>
                <div className="col-lg-1 col-1">

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  })
);
