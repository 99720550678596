import { inject, observer } from "mobx-react";
import * as React from "react";
import { Selector } from "../../Tools/MySelector";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Colors, invertDark, invertSolidDark } from "../../Config/colors.config";
import { MyFileInput } from "../../Tools/MyFileInput";
import { MyButton } from "../../Tools/MyButton";
import HelpIcon from '@mui/icons-material/Help';
import Radio from '@mui/material/Radio';

export const Page2 = inject("store")(
  observer((props) => {
    const { theme } = props.store.common;
    const { _handleChangeInputData, inputData} = props.store.timeseries;

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip
          {...props}
          classes={{ popper: className }}
          placement="right-start"
          arrow
        />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: "#f5f5f9",
          color: "rgba(0, 0, 0, 0.87)",
          minWidth: 380,
          fontSize: theme.typography.pxToRem(25),
          border: "1px solid #dadde9",
        },
        [`& .${tooltipClasses.arrow}`]: {
          color: "#f5f5f9",
        },
      }));
    const tempData = [
      { value: 1, label: "Every 15 minutes" },
      { value: 2, label: "Every 30 minutes" },
      { value: 3, label: "Hourly" },
      { value: 4, label: "Daily" },
      { value: 5, label: "Weekly" },
      { value: 6, label: "Monthly" },
    ];
    const consumptionData = [
      { value: 1, label: "Electricity" },
      { value: 2, label: "Natural Gas" },
      { value: 3, label: "Liquified Petroleum Gas (LPG)" },
      { value: 4, label: "Purchased Cooling" },
      { value: 5, label: "Purchased Heating" },
      { value: 6, label: "Purchased Water" },
    ]
    const unitData = [
      { value: 1, label: "kWh" },
      { value: 2, label: "mmBtu" },
      { value: 3, label: "Refrigeration Ton (RT)" },
      { value: 4, label: "kg" },
      { value: 5, label: "Litres" },

    ]
    const numeratorData = [
      { value: 1, label: "10" },
      { value: 2, label: "20" },
      { value: 3, label: "30" },
      { value: 4, label: "40" },
      { value: 5, label: "50" },
    ]
    const facilityDocuments = [
      { value: 1, label: "Floor Plans" },
      { value: 2, label: "Single Line Diagrams" },
      { value: 3, label: "Chilled Water Piping Schematics" },
      { value: 4, label: "Potable Water Piping Schematics" },
    ]

    return (
      <div>
        <div
          className=""
          style={{
            marginLeft: "5px",
          }}
        >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="col-lg-12 col-12" style={{}}>

                        <div
                            className=" mb-3 mt-3"
                            style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
                        >
                            <div className="col-lg-2 col-2">
                                <div className=" d-flex">
                                    <div
                                        className="col d-flex"
                                        style={{ marginTop: "20px", marginLeft: "15px" }}
                                    >
                                        Inflexible Electricity:
                                        <div data-toggle="tooltip"
                                           title="Inflexible Electricity"
                                           style={{maxWidth: "fit-content"}}> 
                                        <HelpIcon
                                            style={{
                                                fill: theme === "dark" ? "#ddd" : "#1565c0",
                                                marginLeft: "4px",
                                            }}
                                        />
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-lg-10 col-10" style={{ marginLeft: "20px" }}>
                                <div
                                    className=""
                                    style={{
                                        display: "flex",
                                        alignItems: "end",
                                        gap: "20px"
                                    }}
                                >
                                    <div
                                        style={{ minWidth: "80px", display: "flex", justifyContent: "space-between" }}
                                    >
                                        <div>
                                            <span
                                                style={{
                                                    minHeight: "50px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "space-around",
                                                    
                                                }}
                                            >
                                                <span style={{  }} >Yes</span>
                                                <Radio
                                                sx={{
                                                    color: "white",
                                                    '&.Mui-checked': {
                                                    color: Colors.light.softGreenBg,
                                                    },
                                                }}
                                                checked={inputData?.inflexEleCheck == "yes"}
                                                onChange={(e) =>
                                                    _handleChangeInputData(
                                                    "yes",
                                                    "inflexEleCheck"
                                                    )}
                                                />
                                            </span>
                                        </div>
                                        <div className="">
                                            <span
                                                style={{
                                                    minHeight: "50px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "space-around",
                                                }}
                                            >
                                                <span style={{  }}>No</span>
                                                <Radio
                                                sx={{
                                                    color: "white",
                                                    '&.Mui-checked': {
                                                    color: Colors.light.softGreenBg,
                                                    },
                                                }}
                                                checked={inputData?.inflexEleCheck == "no"}
                                                onChange={(e) =>
                                                    _handleChangeInputData(
                                                    "no",
                                                    "inflexEleCheck"
                                                    )}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                        <label style={{  marginBottom: '5px' }}>Frequency</label>
                                        <Selector
                                            getOptionLabel={(e) => (
                                                <div style={{ display: "flex", textAlign: "left" }}>
                                                    <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                </div>
                                            )}
                                            menuListColor={Colors.Dark[0]}
                                            options={consumptionData}
                                            value={consumptionData.filter(
                                              (v) => v.label === inputData?.inflexEleFrequency
                                            )}
                                            placeholder={"Every minute"}
                                        _handleSelect={(e) =>
                                          _handleChangeInputData(e.label, "inflexEleFrequency")
                                        }
                                        />
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                        <label style={{  marginBottom: '5px' }}>Horizon</label>
                                        <Selector
                                            getOptionLabel={(e) => (
                                                <div style={{ display: "flex", textAlign: "left" }}>
                                                    <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                </div>
                                            )}
                                            menuListColor={Colors.Dark[0]}
                                            options={consumptionData}
                                            value={consumptionData.filter(
                                              (v) => v.label === inputData?.inflexEleHorizon
                                            )}
                                            placeholder={"365 dayz"}
                                        _handleSelect={(e) =>
                                          _handleChangeInputData(e.label, "inflexEleHorizon")
                                        }
                                        />
                                    </div>
                                    <div className="">
                                        <MyFileInput
                                            text=""
                                            id={"inflexEleFile"}
                                            theme={theme}
                                            fontSize={"15px"}
                                            toolTip={true}
                                        file={inputData?.inflexEleFile}
                                        onChange={(e) =>
                                          _handleChangeInputData(e.target?.files[0], "inflexEleFile")
                                        }
                                        />
                                    </div>
                                    <MyButton
                                        text={'Upload'}
                                        id={"button"}
                                        custom={'custom'}
                                        customColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
                                        customHoverColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg} />
                                    
                                </div>
                            </div>
                        </div>

                        <div
                            className=" mb-3"
                            style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
                        >
                            <div className="col-lg-2 col-2">
                                <div className=" d-flex">
                                    <div
                                        className="col"
                                        style={{ marginTop: "20px", marginLeft: "15px" }}
                                    >
                                        H2:
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-10 col-10" style={{ marginLeft: "20px" }}>
                                <div
                                    className=""
                                    style={{
                                        display: "flex",
                                        alignItems: "end",
                                        gap: "20px"
                                    }}
                                >
                                    <div
                                        style={{ minWidth: "80px", display: "flex", justifyContent: "space-between" }}
                                    >
                                        <div>
                                            <span
                                                style={{
                                                    minHeight: "50px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "space-around",
                                                }}
                                            >
                                                <span style={{  }} >Yes</span>
                                                <Radio
                                                sx={{
                                                    color: "white",
                                                    '&.Mui-checked': {
                                                    color: Colors.light.softGreenBg,
                                                    },
                                                }}
                                                checked={inputData?.H2LoadCheck == "yes"}
                                                onChange={(e) =>
                                                    _handleChangeInputData(
                                                    "yes",
                                                    "H2LoadCheck"
                                                    )}
                                                />
                                            </span>
                                        </div>
                                        <div className="">
                                            <span
                                                style={{
                                                    minHeight: "50px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "space-around",
                                                }}
                                            >
                                                <span style={{  }}>No</span>
                                                <Radio
                                                sx={{
                                                    color: "white",
                                                    '&.Mui-checked': {
                                                    color: Colors.light.softGreenBg,
                                                    },
                                                }}
                                                checked={inputData?.H2LoadCheck == "no"}
                                                onChange={(e) =>
                                                    _handleChangeInputData(
                                                    "no",
                                                    "H2LoadCheck"
                                                    )}
                                                />
                                                
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                    <label style={{  marginBottom: '5px' }}>Frequency</label>
                                        <Selector
                                            getOptionLabel={(e) => (
                                                <div style={{ display: "flex", textAlign: "left" }}>
                                                    <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                </div>
                                            )}
                                            menuListColor={Colors.Dark[0]}
                                            options={consumptionData}
                                            value={consumptionData.filter(
                                              (v) => v.label === inputData?.h2Frequency
                                            )}
                                            placeholder={"Every minute"}
                                        _handleSelect={(e) =>
                                          _handleChangeInputData(e.label, "h2Frequency")
                                        }
                                        />
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                    <label style={{  marginBottom: '5px' }}>Horizon</label>
                                        <Selector
                                            getOptionLabel={(e) => (
                                                <div style={{ display: "flex", textAlign: "left" }}>
                                                    <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                </div>
                                            )}
                                            menuListColor={Colors.Dark[0]}
                                            options={consumptionData}
                                            value={consumptionData.filter(
                                              (v) => v.label === inputData?.h2Horizon
                                            )}
                                            placeholder={"365 day"}
                                        _handleSelect={(e) =>
                                          _handleChangeInputData(e.label, "h2Horizon")
                                        }
                                        />
                                    </div>
                                    <div className="">
                                        <MyFileInput
                                            text=""
                                            id={"H2LoadFile"}
                                            theme={theme}
                                            fontSize={"15px"}
                                            toolTip={true}
                                        file={inputData?.H2LoadFile}
                                        onChange={(e) =>
                                          _handleChangeInputData(e.target.files[0], "H2LoadFile")
                                        }
                                        />
                                    </div>
                                    <MyButton
                                        text={'Upload'}
                                        id={"button"}
                                        custom={'custom'}
                                        customColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
                                        customHoverColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg} />
                                </div>
                            </div>
                        </div>

                        <div
                            className=" mb-3"
                            style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
                        >
                            <div className="col-lg-2 col-2">
                                <div className=" d-flex">
                                    <div
                                        className="col"
                                        style={{ marginTop: "20px", marginLeft: "15px" }}
                                    >
                                        Cooling:
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-10 col-10" style={{ marginLeft: "20px" }}>
                                <div
                                    className=""
                                    style={{
                                        display: "flex",
                                        alignItems: "end",
                                        gap: "20px"
                                    }}
                                >
                                    <div
                                        style={{ minWidth: "80px", display: "flex", justifyContent: "space-between" }}
                                    >
                                        <div>
                                            <span
                                                style={{
                                                    minHeight: "50px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "space-around",
                                                }}
                                            >
                                                <span style={{  }} >Yes</span>
                                                <Radio
                                                sx={{
                                                    color: "white",
                                                    '&.Mui-checked': {
                                                    color: Colors.light.softGreenBg,
                                                    },
                                                }}
                                                checked={inputData?.coolingLoadCheck == "yes"}
                                                onChange={(e) =>
                                                    _handleChangeInputData(
                                                    "yes",
                                                    "coolingLoadCheck"
                                                    )}
                                                />
                                            </span>
                                        </div>
                                        <div className="">
                                            <span
                                                style={{
                                                    minHeight: "50px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "space-around",
                                                }}
                                            >
                                                <span style={{  }}>No</span>
                                                <Radio
                                                sx={{
                                                    color: "white",
                                                    '&.Mui-checked': {
                                                    color: Colors.light.softGreenBg,
                                                    },
                                                }}
                                                checked={inputData?.coolingLoadCheck == "no"}
                                                onChange={(e) =>
                                                    _handleChangeInputData(
                                                    "no",
                                                    "coolingLoadCheck"
                                                    )}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                    <label style={{  marginBottom: '5px' }}>Frequency</label>

                                        <Selector
                                            getOptionLabel={(e) => (
                                                <div style={{ display: "flex", textAlign: "left" }}>
                                                    <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                </div>
                                            )}
                                            menuListColor={Colors.Dark[0]}
                                            options={consumptionData}
                                            value={consumptionData.filter(
                                              (v) => v.label === inputData?.coolingFrequency
                                            )}
                                            placeholder={"Every minute"}
                                        _handleSelect={(e) =>
                                          _handleChangeInputData(e.label, "coolingFrequency")
                                        }
                                        />
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                    <label style={{  marginBottom: '5px' }}>Horizon</label>

                                        <Selector
                                            getOptionLabel={(e) => (
                                                <div style={{ display: "flex", textAlign: "left" }}>
                                                    <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                </div>
                                            )}
                                            menuListColor={Colors.Dark[0]}
                                            options={consumptionData}
                                            value={consumptionData.filter(
                                              (v) => v.label === inputData?.coolingHorizon
                                            )}
                                            placeholder={"365 day"}
                                        _handleSelect={(e) =>
                                          _handleChangeInputData(e.label, "coolingHorizon")
                                        }
                                        />
                                    </div>
                                    <div className="">
                                        <MyFileInput
                                            text=""
                                            id={"coolingLoadFile"}
                                            theme={theme}
                                            fontSize={"15px"}
                                            toolTip={true}
                                        file={inputData?.coolingLoadFile}
                                        onChange={(e) =>
                                          _handleChangeInputData(e.target.files[0], "coolingLoadFile")
                                        }
                                        />
                                    </div>
                                    <MyButton
                                        text={'Upload'}
                                        id={"button"}
                                        custom={'custom'}
                                        customColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
                                        customHoverColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
  })
);
