import { inject, observer } from "mobx-react"
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Colors } from "../../Config/colors.config";

// import { Colors, invertDark } from "../../Assets/Config/color.config"
// import "./target.css"
// import { MyConstants } from "../../Constants/MyConstants"
// import { useNavigate } from "react-router-dom"
// import "../../config"

export const Sidebar = inject("store")(
  observer((props) => {
    // const { theme } = props.store.common;
    // const { selectedTab, _handleChangeSelectedTab } = props.store.target;
    // const navigate = useNavigate();
    const MyConstants =[
        { title: "Home", icon: <i className="bi bi-house-fill"></i> },
      { title: "List", icon: <i className="bi bi-list-task"></i> },
    //   { title: "Chiller",icon:<AddBoxIcon style={{fontSize:"32px"}}/>}
    ]

    return (
      <div className="shadow-lg py-3 d-flex flex-column justify-content-start align-items-center" style={{ background: 
    //   theme == "light" ? Colors.light.active : 
      Colors.light.darkGreenBg, borderRadius: "15px" }}>
        {
          MyConstants?.map((item, index) => {
            return (
              <div
                key={index}
                className={`d-flex justify-content-center align-items-center 
            }`
            }
                style={{ color: 
                    // selectedTab === item.title ? 
                    Colors.light.softGreenBg
                    // : invertDark(theme)
                    , fontSize:25, cursor: "pointer", borderRadius: 8, width: 50, height: 50 }}
                // onClick={() => {
                //   _handleChangeSelectedTab(item.title)
                //   selectedTab !== item.title && navigate("/e-OPT?tab=" + item.title)
                // }}
              >
                {item.icon}
              </div>
            )
          })
        }
      </div>
    )
  })
)
