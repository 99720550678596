// import { invertDark, Colors } from "../Assets/Config/color.config";
import * as React from "react";
import { invertDark,invertSolidDark,Colors } from '../Config/colors.config';
import { useTranslation } from 'react-i18next';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
    IconButton,
    Collapse,
    Box,
    Typography,
  } from "@mui/material";
  import { styled } from "@mui/material/styles";
  import HelpIcon from '@mui/icons-material/Help';
// import "../config"

export const MyFileInput = (props) => {
    const { onChange, file, fontSize, fileType, theme, text, id,toolTip } = props;
    const { t } = useTranslation("common");
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip
          {...props}
          classes={{ popper: className }}
          placement="right-start"
          arrow
        />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: "#f5f5f9",
          color: "rgba(0, 0, 0, 0.87)",
          minWidth: 380,
          fontSize: theme.typography.pxToRem(25),
          border: "1px solid #dadde9",
        },
        [`& .${tooltipClasses.arrow}`]: {
          color: "#f5f5f9",
        },
      }));

    return (
        <div className="d-flex justify-content-center align-items-center" style={{gap:'10px'}}>
            <div
                className="row p-0 m-0 btn text-center"
                style={{
                    backgroundColor: Colors[theme].softGreenBg,
                    // width: "130px",
                    // minWidth: 130,
                    borderRadius: 5,
                    fontSize: fontSize || 14
                }}
            >
                <label
                    className="d-flex align-items-center justify-content-center"
                    htmlFor={id}
                    style={{ cursor: "pointer",borderRadius: 5, height: "43px", color: invertSolidDark(theme),padding:'0px 12px'
                 }}
                >
                    <input
                        style={{
                            position: "absolute",
                            // width: "130px",
                            display: "none",
                            margin: 12,
                            top: 10,
                            cursor: 'pointer'
                        }}
                        type="file"
                        id={id}
                        onChange={onChange}
                        accept={fileType || ".xlsx,.pdf, image/*, .txt"}
                    />
                    <span style={{color:invertSolidDark(theme)}}>
                        {t("Choose a file") + text}
                    </span>
                </label>
            </div>
            {
                toolTip ? (<HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography fontSize={14}>
                          {"Data caneva Time stamp"}: {"Value"}
                        </Typography> 
                      </React.Fragment>
                    }>
                    <HelpIcon
                    //   onClick={(e) => e.stopPropagation()}
                      style={{
                        fill: theme === "dark" ? "#ddd" : "#1565c0",
                        marginLeft: "8px",
                      }}
                    />
                  </HtmlTooltip>) : null
            }
            
            <div className="row" style={{width:'130px',color:invertSolidDark(theme)}}>
                <div className="d-flex">
                    {file ?
                        <span
                            className="py-auto px-1"
                            style={{ fontSize: 14, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                        >
                            {file.name}
                        </span>
                        : <span className="" style={{ opacity: 0.6, fontSize: 14, color: theme == "light" ? Colors.light.textColor : Colors.light.secBorderColor }}>{t("No File Chosen")}</span>
                    }
                </div>

            </div>

        </div>
    )
}