import { inject, observer } from "mobx-react";
import { Sidebar } from "./Sidebar";
import {Home} from "./Home";
// import { Home } from "./Home";
// import { ListOfEquipments } from "./ListOfEquipments";
// import { TypicalBMSData } from "./TypicalBMSData";
// import { AddCoolingData } from "./AddCoolingData";
// import "../../config"

export const Container = inject("store")(
  observer((props) => {
    // const { selectedTab } = props.store.target;

    const childComp = (tab) => {
    //   switch (tab) {
    //     case "Home": return <Home />;
    //     // case "List": return <ListOfEquipments />
    //     case "List": return <TypicalBMSData />
    //     case "Chiller": return <AddCoolingData />
    //   }
    }

    return (
      <div className="container-fluid d-flex p-2" style={{ minHeight: "97%"}}>
        <Sidebar />
        <div className="ps-2 w-100">
            <Home/>
          {/* {childComp(selectedTab)} */}
        </div>
      </div>
    );
  })
);


