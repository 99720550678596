// import { Colors } from "../Assets/Config/color.config";
import { inject, observer } from "mobx-react";
// import { useTranslation } from "react-i18next";
// import "../config"

export const FooterBar = inject("store")(
  observer((props) => {
    // const { t } = useTranslation("authentication");
    // const { theme } = props.store.common;

    return (
      // <div className="text-center py-1 fixed-bottom" style={{
      //   fontSize: 14,
      //   // color: theme === "dark" ? Colors.dark.textColor : Colors.light.textColor,
      //   // background: theme === "dark" ? 'linear-gradient(-60deg, rgb(16, 24, 65), rgb(32, 43, 96))' : 'linear-gradient(-60deg, rgb(246, 246, 246), rgb(246, 246, 246))',
      //   // borderTop: theme === "dark" ? "1px solid #ffffff20" : "1px solid #00000020",
      //   // display: props.authenticated ? "block" : "none"
      //   color:"white",
      //   background:"red",
      //   borderTop:"1px solid white",
      //   display:"block"
      // }}>
      //   <span>Powered by
      //     <a href="https://www.evercomm.com.sg" target="_blank">
      //       {
      //       // theme === "dark" ?
      //         <img style={{ width: "auto", height: "15px", marginLeft: "5px", marginTop: "-7px", marginRight: "5px" }} src='./evercomm-singapore-white.png' alt={t("Evercomm")} />
      //       //   :
      //       //   <img style={{ width: "auto", height: "15px", marginLeft: "5px", marginTop: "-7px", marginRight: "5px" }} src='./evercomm-singapore.png' alt={t("Evercomm")} />
      //       }
      //     </a>
      //     Version 1.6
      //   </span>
      // </div>
      <div className="text-center py-1 fixed-bottom text-white">Footer</div>
    );
  })
);

export default FooterBar;
