import * as React from "react";
import { inject, observer } from "mobx-react";
import { MyInput } from "../../Tools/MyInput";
import { Colors, invertDark, invertSolidDark } from "../../Config/colors.config";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
    IconButton,
    Collapse,
    Box,
    Typography,
  } from "@mui/material";
  import { styled } from "@mui/material/styles";
  import HelpIcon from '@mui/icons-material/Help';

export const Page8 = inject("store")(
  observer((props) => {
    const { theme } = props.store.common;
    const {inputData,_handleChangeInputData} =props.store.timeseries;

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip
          {...props}
          classes={{ popper: className }}
          placement="right-start"
          arrow
        />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: "#f5f5f9",
          color: "rgba(0, 0, 0, 0.87)",
          minWidth: 380,
          fontSize: theme.typography.pxToRem(25),
          border: "1px solid #dadde9",
        },
        [`& .${tooltipClasses.arrow}`]: {
          color: "#f5f5f9",
        },
      }));
    const tempData = [
      { value: 1, label: "Every 15 minutes" },
      { value: 2, label: "Every 30 minutes" },
      { value: 3, label: "Hourly" },
      { value: 4, label: "Daily" },
      { value: 5, label: "Weekly" },
      { value: 6, label: "Monthly" },
    ];
    const consumptionData = [
      { value: 1, label: "Electricity" },
      { value: 2, label: "Natural Gas" },
      { value: 3, label: "Liquified Petroleum Gas (LPG)" },
      { value: 4, label: "Purchased Cooling" },
      { value: 5, label: "Purchased Heating" },
      { value: 6, label: "Purchased Water" },
    ]
    const unitData = [
      { value: 1, label: "kWh" },
      { value: 2, label: "mmBtu" },
      { value: 3, label: "Refrigeration Ton (RT)" },
      { value: 4, label: "kg" },
      { value: 5, label: "Litres" },

    ]
    const numeratorData = [
      { value: 1, label: "10" },
      { value: 2, label: "20" },
      { value: 3, label: "30" },
      { value: 4, label: "40" },
      { value: 5, label: "50" },
    ]
    const facilityDocuments = [
      { value: 1, label: "Floor Plans" },
      { value: 2, label: "Single Line Diagrams" },
      { value: 3, label: "Chilled Water Piping Schematics" },
      { value: 4, label: "Potable Water Piping Schematics" },
    ]
    return (
      <div>
        <div
          className=""
          style={{
            // background: theme == "light" ? Colors.light.active : "#010849",
            // borderRadius: "15px",
            // width: "100%",
            marginLeft: "5px",
            // marginTop: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="col-lg-12 col-12" style={{}}>
              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-3 col-3">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px"}}>
                      PV <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography fontSize={14}>
                          {"PV"}
                        </Typography> 
                      </React.Fragment>
                    }>
                    <HelpIcon
                    //   onClick={(e) => e.stopPropagation()}
                      style={{
                        fill: theme === "dark" ? "#ddd" : "#1565c0",
                        marginLeft: "8px",
                      }}
                    />
                  </HtmlTooltip>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-9" style={{marginLeft:""}}>
                    <div className="row" style={{display:'flex',alignItems:'center'}}>
                  <div className="col-lg-2 col-2">
                <label htmlFor="" style={{color:invertSolidDark(theme)}}>Min</label>
                  <MyInput
                    required={true}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    id={"PVmin"}
                    type={"number"}
                    disabled={false}
                    value={inputData?.PVMin}
                    onChange={(e)=>_handleChangeInputData(e.target.value,"PVMin")}
                    
                    />
                  </div>
                  <div className="col-lg-2 col-2">
                <label htmlFor="" style={{color:invertSolidDark(theme)}}>Max</label>
                  <MyInput
                    required={true}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    id={"PVmax"}
                    type={"number"}
                    disabled={false}
                    value={inputData?.PVMax}
                    onChange={(e)=>_handleChangeInputData(e.target.value,"PVMax")}
                    
                    />
                  </div>
                  <div className="col-lg-2 col-2" style={{color:invertSolidDark(theme),marginTop:'20px'}}>
                    kW
                  </div>
                  </div>

                </div>
              </div>
              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-3 col-3">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px"}}>
                      Battery <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography fontSize={14}>
                          {"Battery"}
                        </Typography> 
                      </React.Fragment>
                    }>
                    <HelpIcon
                    //   onClick={(e) => e.stopPropagation()}
                      style={{
                        fill: theme === "dark" ? "#ddd" : "#1565c0",
                        marginLeft: "8px",
                      }}
                    />
                  </HtmlTooltip>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-9" style={{marginLeft:""}}>
                    <div className="row" style={{display:'flex',alignItems:'center'}}>
                  <div className="col-lg-2 col-2">
                <label htmlFor="" style={{color:invertSolidDark(theme)}}>Min</label>
                  <MyInput
                    required={true}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    id={"batterymin"}
                    type={"number"}
                    disabled={false}
                    value={inputData?.batteryMin}
                    onChange={(e) => _handleChangeInputData(e.target.value,"batteryMin")}
                    
                    />
                  </div>
                  <div className="col-lg-2 col-2">
                <label htmlFor="" style={{color:invertSolidDark(theme)}}>Max</label>
                  <MyInput
                    required={true}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    id={"batterymax"}
                    type={"number"}
                    disabled={false}
                    value={inputData?.batteryMax}
                    onChange={(e) => _handleChangeInputData(e.target.value,"batteryMax")}

                    
                    />
                  </div>
                  <div className="col-lg-2 col-2" style={{color:invertSolidDark(theme),marginTop:'20px'}}>
                    kW
                  </div>
                  </div>

                </div>
              </div>
              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-3 col-3">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px"}}
                    >
                      Thermal Storage <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography fontSize={14}>
                          {"Thermal Storage"}
                        </Typography> 
                      </React.Fragment>
                    }>
                    <HelpIcon
                    //   onClick={(e) => e.stopPropagation()}
                      style={{
                        fill: theme === "dark" ? "#ddd" : "#1565c0",
                        marginLeft: "8px",
                      }}
                    />
                  </HtmlTooltip>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-9" style={{marginLeft:""}}>
                    <div className="row" style={{display:'flex',alignItems:'center'}}>
                  <div className="col-lg-2 col-2">
                <label htmlFor="" style={{color:invertSolidDark(theme)}}>Min</label>
                  <MyInput
                    required={true}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    id={"therStoragemin"}
                    type={"number"}
                    disabled={false}
                    value={inputData?.therStorageMin}
                    onChange={(e) => _handleChangeInputData(e.target.value,"therStorageMin")}
                    
                    />
                  </div>
                  <div className="col-lg-2 col-2">
                <label htmlFor="" style={{color:invertSolidDark(theme)}}>Max</label>
                  <MyInput
                    required={true}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    id={"therStoragemax"}
                    type={"number"}
                    disabled={false}
                    value={inputData?.therStorageMax}
                    onChange={(e) => _handleChangeInputData(e.target.value,"therStorageMax")}
                    
                    />
                  </div>
                  <div className="col-lg-2 col-2" style={{color:invertSolidDark(theme),marginTop:'20px'}}>
                    kW
                  </div>
                  </div>

                </div>
              </div>
              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-3 col-3">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px"}}
                    >
                      Genset <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography fontSize={14}>
                          {"Genset"}
                        </Typography> 
                      </React.Fragment>
                    }>
                    <HelpIcon
                    //   onClick={(e) => e.stopPropagation()}
                      style={{
                        fill: theme === "dark" ? "#ddd" : "#1565c0",
                        marginLeft: "8px",
                      }}
                    />
                  </HtmlTooltip>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-9" style={{marginLeft:""}}>
                    <div className="row" style={{display:'flex',alignItems:'center'}}>
                  <div className="col-lg-2 col-2">
                <label htmlFor="" style={{color:invertSolidDark(theme)}}>Min</label>
                  <MyInput
                    required={true}
                    id={"gensetmin"}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    type={"number"}
                    disabled={false}
                    value={inputData?.gensetMin}
                    onChange={(e) => _handleChangeInputData(e.target.value,"gensetMin")}
                    
                    />
                  </div>
                  <div className="col-lg-2 col-2">
                <label htmlFor="" style={{color:invertSolidDark(theme)}}>Max</label>
                  <MyInput
                    required={true}
                    id={"gensetmax"}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    type={"number"}
                    disabled={false}
                    value={inputData?.gensetMax}
                    onChange={(e) => _handleChangeInputData(e.target.value,"gensetMax")}
                    
                    />
                  </div>
                  <div className="col-lg-2 col-2" style={{color:invertSolidDark(theme),marginTop:'20px'}}>
                    kW
                  </div>
                  </div>

                </div>
              </div>
              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-3 col-3">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px"}}
                    >
                      CHP <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography fontSize={14}>
                          {"CHP"}
                        </Typography> 
                      </React.Fragment>
                    }>
                    <HelpIcon
                    //   onClick={(e) => e.stopPropagation()}
                      style={{
                        fill: theme === "dark" ? "#ddd" : "#1565c0",
                        marginLeft: "8px",
                      }}
                    />
                  </HtmlTooltip>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-9" style={{marginLeft:""}}>
                    <div className="row" style={{display:'flex',alignItems:'center'}}>
                  <div className="col-lg-2 col-2">
                <label htmlFor="" style={{color:invertSolidDark(theme)}}>Min</label>
                  <MyInput
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    id={"CHPmin"}
                    type={"number"}
                    disabled={false}
                    value={inputData?.CHPMin}
                    onChange={(e) => _handleChangeInputData(e.target.value,"CHPMin")}
                    
                    />
                  </div>
                  <div className="col-lg-2 col-2">
                <label htmlFor="" style={{color:invertSolidDark(theme)}}>Max</label>
                  <MyInput
                    required={true}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    id={"CHPmax"}
                    type={"number"}
                    disabled={false}
                    value={inputData?.CHPMax}
                    onChange={(e) => _handleChangeInputData(e.target.value,"CHPMax")}
                    
                    />
                  </div>
                  <div className="col-lg-2 col-2" style={{color:invertSolidDark(theme),marginTop:'20px'}}>
                    kW
                  </div>
                  </div>

                </div>
              </div>
              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-3 col-3">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px"}}
                    >
                      Cooling Import Capacity <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography fontSize={14}>
                          {"Cooling Import Capacity"}
                        </Typography> 
                      </React.Fragment>
                    }>
                    <HelpIcon
                    //   onClick={(e) => e.stopPropagation()}
                      style={{
                        fill: theme === "dark" ? "#ddd" : "#1565c0",
                        marginLeft: "8px",
                      }}
                    />
                  </HtmlTooltip>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-9" style={{marginLeft:""}}>
                    <div className="row" style={{display:'flex',alignItems:'center'}}>
                  <div className="col-lg-2 col-2">
                <label htmlFor="" style={{color:invertSolidDark(theme)}}>Min</label>
                  <MyInput
                    required={true}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    id={"CoolImportCapmin"}
                    type={"number"}
                    disabled={false}
                    value={inputData?.CoolImportCapMin}
                    onChange={(e) => _handleChangeInputData(e.target.value,"CoolImportCapMin")}
                    
                    />
                  </div>
                  <div className="col-lg-2 col-2">
                <label htmlFor="" style={{color:invertSolidDark(theme)}}>Max</label>
                  <MyInput
                    required={true}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    id={"CoolImportCapmax"}
                    type={"number"}
                    disabled={false}
                    value={inputData?.CoolImportCapMax}
                    onChange={(e) => _handleChangeInputData(e.target.value,"CoolImportCapMax")}
                    
                    />
                  </div>
                  <div className="col-lg-2 col-2" style={{color:invertSolidDark(theme),marginTop:'20px'}}>
                    kW
                  </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  })
);
