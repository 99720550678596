import common from './common.store';
import timeseries from './timeseries';

class Store {
	common = null;
	timeseries = null

	constructor(common, timeseries) {
		this.common = common;
		this.timeseries = timeseries;
	}
}

export default new Store(common,timeseries);
