import { inject, observer } from 'mobx-react';
import Home from './pages/Home';
import { Container } from './components/TimeSeriesData/Container';

import { Routes, Route, Navigate } from 'react-router-dom';
import NavBar from './App/NavBar';
import FooterBar from './App/FooterBar';
import { useEffect } from 'react';

const App = inject('store')(
	observer((props) => {
		const { theme } = props.store.common;
		localStorage.setItem('theme','dark');
		console.log('theme sss ===>', theme);

		const components = [
			// {
			// 	path: '/home',
			// 	component: <Home />,
			// },
			{
				path:'/time-series',
				component:< Container />
			}

		];

		useEffect(() => {
			let x = document.getElementsByTagName('BODY')[0];
			!theme || theme === 'dark'
				? (x.style.backgroundImage = 'linear-gradient(-45deg,#141C48,#0B6E4F)')
				: (x.style.backgroundImage = 'linear-gradient(-60deg,#e9e9e9,#e9e9e9)');
		}, [theme]);

		return (
			<div className="body-scroll">
				{/* <NavBar/> */}
				<Routes>
					
					{components.map((v, k) => (
						<>
						
						<Route
							path={v.path}
							key={k}
							element={v.component}
						/>
						
						</>
					))}
					
					<Route
						path="*"
						element={
							<Navigate
								to="/time-series"
								replace
							/>
						}
					/>
					<Route
						path="/"
						element={
							<Navigate
								to="/time-series"
								replace
							/>
						}
					/>
				</Routes>
				{/* <FooterBar/> */}
			</div>
		);
	}),
);

export default App;
