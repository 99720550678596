import { inject, observer } from "mobx-react";
import * as React from "react";
import { Colors, invertDark, invertSolidDark } from "../../Config/colors.config";
import { MyFileInput } from "../../Tools/MyFileInput";
import { MyButton } from "../../Tools/MyButton";
import HelpIcon from '@mui/icons-material/Help';
import { MyInput } from "../../Tools/MyInput";

export const Page6 = inject("store")(
  observer((props) => {
    const { theme } = props.store.common;
    const { _handleChangeInputData, inputData} = props.store.timeseries;

    return (
      <div>
        <div
          className=""
          style={{
            marginLeft: "5px",
          }}
        >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="col-lg-12 col-12" style={{}}>

                        <div
                            className=" mb-3 mt-3"
                            style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
                        >
                            <div className="col-lg-2 col-2">
                                <div className=" d-flex">
                                    <div
                                        className="col d-flex"
                                        style={{ marginTop: "20px", marginLeft: "15px"}}
                                    >
                                        PV 1:
                                        <div data-toggle="tooltip"
                                           title="VP 1"
                                           style={{maxWidth: "fit-content"}}> 
                                        <HelpIcon
                                            style={{
                                                fill: theme === "dark" ? "#ddd" : "#1565c0",
                                                marginLeft: "4px",
                                            }}
                                        />
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-lg-10 col-10" style={{}}>
                                <div
                                    className=""
                                    style={{
                                        display: "flex",
                                        alignItems: "end",
                                        gap: "20px"
                                    }}
                                >
                                  
                                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                        <label style={{  marginBottom: '5px' }}>Capacity</label>
                                        <MyInput
                                            type="text"
                                            value={inputData?.pvCapacity || ''} // Set an empty string if inputData?.capacity is undefined
                                            onChange={(e) => _handleChangeInputData(e.target.value, "pvCapacity")}
                                        />
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                        <label style={{  marginBottom: '5px' }}>Area</label>
                                        <MyInput
                                            type="text"
                                            value={inputData?.pvArea || ''} // Set an empty string if inputData?.capacity is undefined
                                            onChange={(e) => _handleChangeInputData(e.target.value, "pvArea")}
                                        />
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                        <label style={{  marginBottom: '5px' }}>Normal efficiency</label>
                                        <MyInput
                                            type="text"
                                            value={inputData?.pvNormalEffi || ''} // Set an empty string if inputData?.capacity is undefined
                                            onChange={(e) => _handleChangeInputData(e.target.value, "pvNormalEffi")}
                                        />
                                    </div>
                             
                                       
                               
                                    <div className="">
                                    <label style={{  marginBottom: '5px' }}>Efficiency map</label>

                                        <MyFileInput
                                            text=""
                                            id={"PV1File"}
                                            theme={theme}
                                            fontSize={"15px"}
                                            toolTip={true}
                                        file={inputData?.PV1File}
                                        onChange={(e) =>
                                          _handleChangeInputData(e.target.files[0], "PV1File")
                                        }
                                        />
                                    </div>
                                    <MyButton
                                        text={'Upload'}
                                        id={"button"}
                                        custom={'custom'}
                                        customColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
                                        customHoverColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg} />
                                    
                                    <div className="col-xl-2 col-lg-3 col-md-3 col-3 ms-auto" >
                                        <label style={{  marginBottom: '5px' }}>Annual maintainance codet</label>
                                        <MyInput
                                            type="text"
                                            value={inputData?.pvMaintainanceCost || ''} // Set an empty string if inputData?.capacity is undefined
                                            onChange={(e) => _handleChangeInputData(e.target.value, "pvMaintainanceCost")}
                                        />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    );
  })
);
