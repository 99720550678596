import { inject, observer } from 'mobx-react';
import { Page1 } from './Page1';
import { Page2 } from './Page2';
import { Page3 } from './Page3';
import { Page4 } from './Page4';
import { Page5 } from './Page5';
import { Page6 } from './Page6';
import { Page7 } from './Page7';
import { Page8 } from './Page8';
import { Page9 } from './Page9';
import { Page10 } from './Page10';
import { Page11 } from './Page11';
import { Page12 } from './Page12';

import { Colors, invertDark, invertSolidDark } from '../../Config/colors.config';

export const Home = inject('store')(
	observer((props) => {
		const { theme } = props.store.common;
		const { selectedPageIndex, _handleNextIndex, _handleBackIndex } = props.store.timeseries;
		const mapData = [
			{
				pageIndex: 1,
				component: <Page1 />,
				pageTitle: 'Energy Carriers Consumption For Baselining',
			},
			{
				pageIndex: 2,
				component: <Page2 />,
				pageTitle: 'Load(Inflexible Demand Inside Scope)',
			},
			{
				pageIndex: 3,
				component: <Page3 />,
				pageTitle: 'System Environments Condition',
			},
			{
				pageIndex: 4,
				component: <Page4 />,
				pageTitle: 'Existing Layout',
			},
			{
				pageIndex: 8,
				component: <Page8 />,
				pageTitle: 'Optimisation Boundaries',
			},
			{
				pageIndex: 9,
				component: <Page9 />,
				pageTitle: 'Optimisation Settings',
			},
			{
				pageIndex: 5,
				component: <Page5 />,
				pageTitle: 'Add CHP Installed',
			},
			{
				pageIndex: 6,
				component: <Page6 />,
				pageTitle: 'Add PV Installed',
			},
			{
				pageIndex: 7,
				component: <Page7 />,
				pageTitle: 'Add Gas Engine Installed',
			},
			{
				pageIndex: 10,
				component: <Page10 />,
				pageTitle: 'Lay out and economics',
			},
			{
				pageIndex: 11,
				component: <Page11 />,
				pageTitle: 'Energy At The Assets Level',
			},
			{
				pageIndex: 12,
				component: <Page12 />,
				pageTitle: 'Energy Carriers At The Sytem Level',
			},
		];
		return (
			<div
				className="h-100"
				// style={{ height: '100%' }}
			>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
					<div
						className="col-lg-7 col-7"
						style={{ fontWeight: 'bold', fontSize: '22px', color: invertSolidDark(theme) }}
					>
						{mapData.filter((v) => v?.pageIndex == selectedPageIndex)?.length > 0
							? mapData.filter((v) => v?.pageIndex == selectedPageIndex)[0].pageTitle
							: 'Default'}
					</div>
					{/* <div className="col-lg-6 col-6">
            <TargetSettingStrapper
              steps={[
                "", "", "", "", ""
              ]}
            />
          </div> */}
					<div className="col-lg-3 col-3">
						{/* <Navigator goTo={"Back To Target Setting"} link="/target-setting" /> */}
					</div>
				</div>
				<div
					style={{
						// overflowY:`${selectedPageIndex == 0 ? "scroll" : ""}`,
            // overflowY:'scroll',
						border: '1px solid black',
						background: Colors.light.darkGreenBg,
						height: 'calc(94vh - 75px)',
						borderRadius: '20px',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'start',
					}}
				>
					<div
						className={
							selectedPageIndex == 4 ||
							selectedPageIndex == 5 ||
							selectedPageIndex == 6 ||
							selectedPageIndex == 7 ||
							selectedPageIndex == 10 ||
							selectedPageIndex == 11 ||
							selectedPageIndex == 12
								? 'col-lg-12 col-12'
								: 'col-lg-10 col-10'
						}
						style={{
							padding: '0px !important',
              height:'100%',
             
							overflowY: 'scroll',
							overflowX: 'hidden',
						}}
					>
						{mapData.filter((v) => v?.pageIndex === selectedPageIndex)?.length > 0 ? (
							mapData.filter((v) => v?.pageIndex === selectedPageIndex)[0].component
						) : (
							<></>
						)}
					</div>
					{
						selectedPageIndex == 4 ||
						selectedPageIndex == 5 ||
						selectedPageIndex == 6 ||
						selectedPageIndex == 7 ||
						selectedPageIndex == 10 ||
						selectedPageIndex == 11 ||
						selectedPageIndex == 12 ? (
							<></>
						) : (
							<div
								className="col-lg-2 col-10"
								style={{ height: '100%', borderLeft: `1px solid ${Colors.dark.greyColor}` }}
							>
								<div style={{color:invertSolidDark(theme)}}>
									<h4
										style={{
											padding: '0px 15px',
											marginTop: '10px',
										}}
									>
										Description
									</h4>
									<div
										style={{
											padding: '0px 30px',
										}}
									>
										Choose The energies that is used in the plant
									</div>
								</div>
							</div>
						)
					}
				</div>
				<div
					className="mt-2"
					style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
				>
					<div
						className="col-lg-2 col-md-3 col-4"
						style={{ display: 'flex', justifyContent: 'end' }}
					>
						{
							<button
								className="btn px-4"
								style={{
									cursor: 'pointer',
									background: Colors.light.softGreenBg,
									color: invertSolidDark(theme),
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
								onClick={() => _handleBackIndex()}
							>
								<i className="bi bi-arrow-left pe-2 pt-1"></i>
								{'Back'}
							</button>
						}

						{
							selectedPageIndex < 12 ?
							<button
								className="btn px-4"
								style={{
									cursor: 'pointer',
									background: Colors.light.softGreenBg,
									color: invertSolidDark(theme),
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									marginLeft: '10px',
								}}
								onClick={() => _handleNextIndex()}
							>
								{'Next'}
								<i
									className="bi bi-arrow-right pe-2 pt-1"
									style={{ marginLeft: '5px' }}
								></i>
							</button>
							:
              <></>
							// <button
							//   className="btn px-4"
							//   style={{
							//     background: Colors.dark.buttonColor,
							//     color: Colors.light.background,
							//     display: "flex",
							//     justifyContent: "space-between",
							//     alignItems: "center",
							//     marginLeft: "10px"
							//   }}
							// // onClick={_handleNextIndex}
							// >

							//   {"Save"}
							//   <SaveIcon style={{ marginLeft: "10px" }} fontSize="14px" />
							// </button>
						}
					</div>
				</div>
			</div>
		);
	}),
);
