// import { useNavigate } from "react-router-dom";
// import { Colors } from "../Assets/Config/color.config";
// import { useEffect, useState } from "react";
// import Store from "../Store";
// import { Cookies } from "react-cookie";
import { inject, observer } from "mobx-react";
// import Logo from '../../public/imgaes/NXPlan-Logo.png'
import { Colors,invertSolidDark } from "../Config/colors.config";
// import { baseURLImage } from "../API/URL";
// import { ThemeSwitch } from "../Tools";
// import { useTranslation } from "react-i18next";
// import UserMenuItem from "./UserMenuItem";
// import LanguageMenuItem from "./LanguageMenuItem";
// import { MyToggleButton } from "../Tools";
// import NotiMenuItem from "./NotiMenuItem";
// import PageSearch from "./PageSearch";
// import "../config"

export const NavBar = inject("store")(
  observer((props) => {
    // const navigate = useNavigate();
    // const { _handleLogout } = Store.login;
    // const cookies = new Cookies();
    // // const userName = cookies.get("userName");
    // const userRole = cookies.get("role");
    // const params = new URLSearchParams(window.location.search);
    const pathName = window.location.pathname;
    // const buildingName = params.get("name");
    // const siteImage = cookies.get("siteImage");
    // const { t } = useTranslation("authentication");

    // const [showLanguageDropDown, setShowLanguageDropDown] = useState(false);
    // const [showDropDown, setShowDropDown] = useState(false);
    // const [notiDropDown,setNotiDropDown]=useState(false);

    // const { surveyInfo } = props.store.survey;
    // const { getModules } = props.store.manageModules;
    // const { addDefaultSrc, theme, onChangeTheme, siteModuleData, role, customEmissionFactorList,getAllChildCompanyCount,getExpiryUserCount,getAllChildCompaniesCount,getExpiryUsersCount, getCustomEmissionFactors, userName } = props.store.common;
    // const { authenticated } = props;
    // const _handleLanguageDropDown = (e) => {
    //   e.stopPropagation();
    //   setShowDropDown(false);
    //   setNotiDropDown(false);
    //   setShowLanguageDropDown(!showLanguageDropDown);
    // };
    
    // const _handleNotiDropDown = (e) => {
    //   e.stopPropagation();
    //   setShowDropDown(false);
    //   setShowLanguageDropDown(false);
    //   setNotiDropDown(!notiDropDown);

    // }
    // const companyName = siteModuleData?.companyName;

    // const _handleUserDropDown = (e) => {
    //   e.stopPropagation();
    //   setShowLanguageDropDown(false);
    //   setNotiDropDown(false);
    //   setShowDropDown(!showDropDown);
    // };

    // useEffect(() => {
    //   if (authenticated) {
    //       getModules()
    //       getCustomEmissionFactors() 
    //       if(role === "superadmin"){
    //         getAllChildCompaniesCount()      
    //         getExpiryUsersCount()      
    //       }
    //   }
    // }, [authenticated])

    // useEffect(() => {

    //   const hideDropDowns = (e) => {
    //     setShowDropDown(false);
    //     setShowLanguageDropDown(false);
    //     setNotiDropDown(false)
    //   };
    //   document.body.addEventListener("click", hideDropDowns);

    //   return () => {
    //     document.body.removeEventListener("click", hideDropDowns);
    //   };
    // }, []);

    return (
      // <div
      //   style={{
      //     fontSize: 18,
      //   //   color:
      //   //     theme === "dark" ? Colors.dark.textColor : Colors.light.textColor,
      //   //   background: theme === "dark" ? 'linear-gradient(-60deg, rgb(16, 24, 65), rgb(32, 43, 96))' : 'linear-gradient(-60deg, rgb(246, 246, 246), rgb(246, 246, 246))',
      //   //   borderBottom: theme === "dark" ? "1px solid #ffffff20" : "1px solid #00000020",
      //   color:"white",
      //   background:Colors.dark.darkGreenBg,
      //   borderBottom:"1px solid white",
      //     zIndex: "1000"
      //   }}
      //   className="d-flex flex-row justify-content-between align-items-center px-3 py-1 mb-3 fixed-top"
      // >
      //   {
      //   // authenticated && 
      //   (
      //     <><div className="d-flex px-3">
      //       <h4
      //         style={{ fontWeight: "bold", cursor: "pointer", margin: 0 }}
      //       //   onClick={() => userRole === 'auditor' ?
      //       //     navigate('/auditor-overview') : navigate("/home")}
      //       >

      //         {
      //       //   companyName && companyName === "MEDS" ?
      //       //     <img
      //       //       className="ms-3"
      //       //       src="/MEDS-logo-new.png"
      //       //       height={"60px"}
      //       //     /> :
      //           <img
      //             className="ms-3"
      //             src={Logo}
      //             height={"50px"}
      //           />}

      //         {/*
      //         <span style={{ color: Colors[theme].primaryColor }}>
      //           {userRole === "auditor" ? t("ESG Map - Auditor View") : t("ESGmap")}
      //           &nbsp;
      //         </span>
      //         */}

      //         {/* {buildingName && (
      //           <>
      //             <i className="bi bi-chevron-right"></i>
      //             <span className="px-2">
      //               <img
      //                 onError={addDefaultSrc}
      //                 src={baseURLImage + siteImage}
      //                 alt={t("Company Logo")}
      //                 height={40}
      //                 style={{ borderRadius: 5 }}
      //               />{" "}
      //               {buildingName}
      //             </span>
      //             <i className="bi bi-chevron-right" /> {surveyInfo.surveyTitle}
      //           </>
      //         )} */}
      //       </h4>
      //       {/* <div className="d-flex align-items-center ms-3">
      //         <p style={{ color: 
      //           // theme === "dark" ? 
      //           "#fff",
      //           //  : "#000", 
      //            fontWeight: "bold", fontSize: 12 }} className="mb-0 me-3">in alignment with</p>
      //         <div className="text-center me-2 rounded-pill pe-2" style={{ 
      //           backgroundColor: 
      //           // theme == "dark" ? 
      //           "#ffffff95" 
      //           // : Colors.light.pillColor,
      //           ,
      //            paddingTop: "1px", paddingBottom: "1px" }}>
      //           <img
      //             src="/ghg_protocol_logo.png"
      //             height={"30px"}
      //           />
      //         </div>
      //         <div className="text-center rounded-pill pe-2" style={{ 
      //           backgroundColor: 
      //           // theme == "dark" ? 
      //           "#ffffff95" 
      //           // : Colors.light.pillColor, 
      //           ,paddingTop: "1px", paddingBottom: "1px" }}>
      //           <img  
      //             className="ms-3"
      //             src="/ISO-Logo.png"
      //             height={"30px"}
      //           />
      //         </div>
      //       </div> */}
      //       {/* {pathName === "/rapid-assessment" &&
      //         <div className="mx-2 my-2">
      //         </div>
      //       } */}
      //     </div>
      //     </>
      //   )}
      //   <div
      //     className="d-flex align-items-center"
      //     style={{ marginLeft: "auto" }}
      //   >
      //     {/* {
      //       (role === "superadmin") && authenticated && (
      //         // <div className="mx-3 d-flex align-items-center position-relative" style={{ cursor: "pointer" }} onClick={() => navigate('/custom-emission-factor-list')}>
      //         //   {
      //         //     customEmissionFactorCount > 0 && (
      //         //       <div className="rounded-circle position-absolute d-flex justify-content-center align-items-center" style={{ top: "-2px", right: "-9px", width: 17, height: 17, backgroundColor: "#1161d5", fontSize: 13 }}>
      //         //         <span className="badge badge-primary">{customEmissionFactorCount}</span>
      //         //       </div>
      //         //     )
      //         //   }
      //         //   <i className="bi bi-bell-fill"></i>
      //         // </div>
      //         <NotiMenuItem theme={theme} showDropDown={notiDropDown} getExpiryUserCount={getExpiryUserCount} getAllChildCompanyCount={getAllChildCompanyCount} customEmissionFactorList={customEmissionFactorList} _handleNotiDropDown={_handleNotiDropDown} onClick={_handleNotiDropDown}/>
              
      //       )
      //     } */}
          
      //     {/* {authenticated && (
      //       <span
      //         className="px-2 py-1 fw-bold"
      //         variant="outlined" 
      //         style={{
      //           fontSize: 12,
      //           borderRadius: "10px",
      //           color : "white",
      //           border: theme === "dark" ? "white" : "black",
      //           backgroundColor : 
      //             role === "org_admin" ? "#7986cb" : 
      //             role === "carbon_admin" ? "#3f51b5" :
      //             role === "carbon_user" ? "#4db6ac" :
      //             role === "child_org_admin" ? "#a1887f" : "#90a4ae"
      //         }}>
      //         {
      //           role === "superadmin" ? "System Admin" :
      //           role === "org_admin" ? "Super Admin" :
      //           role === "carbon_admin" ? "Operator" :
      //           role === "custom_validator" ? "Custom Validator":
      //           role === "child_org_admin" ? "Admin": "Viewer"
      //         }
      //       </span>          
      //     )
        
      //     } */}

      //     {/* <LanguageMenuItem
      //       userName={userName}
      //       theme={theme}
      //       authenticated={authenticated}
      //       showDropDown={showLanguageDropDown}
      //       onClick={_handleLanguageDropDown}
      //     />
      //     <ThemeSwitch
      //       style={{ marginRight: 0 }}
      //       onChangeTheme={onChangeTheme}
      //       theme={theme}
      //     />
      //     {authenticated && (
      //       <UserMenuItem
      //         userName={userName}
      //         theme={theme}
      //         role={role}
      //         authenticated={authenticated}
      //         showDropDown={showDropDown}
      //         _handleLogout={_handleLogout}
      //         customEmissionFactorList={customEmissionFactorList}
      //         onClick={_handleUserDropDown}
      //       />
      //     )} */}
      //   </div>
      // </div>
      <div>
        NavBar
      </div>
    )
  })
);

export default NavBar;
