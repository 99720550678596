import * as React from "react";
import { inject, observer } from "mobx-react";
import { Selector } from "../../Tools/MySelector";
import { MyFileInput } from "../../Tools/MyFileInput";
import { Colors, invertDark, invertSolidDark } from "../../Config/colors.config";
import { MyButton } from "../../Tools/MyButton";
import DownloadIcon from '@mui/icons-material/Download';
import Radio from '@mui/material/Radio';

export const Page1 = inject("store")(
  observer((props) => {
    const { theme } = props.store.common;
    const {inputData,_handleChangeInputData} =props.store.timeseries;

    const tempData = [
      { value: 1, label: "Every 15 minutes" },
      { value: 2, label: "Every 30 minutes" },
      { value: 3, label: "Hourly" },
      { value: 4, label: "Daily" },
      { value: 5, label: "Weekly" },
      { value: 6, label: "Monthly" },
    ];
    const consumptionData = [
      { value: 1, label: "Electricity" },
      { value: 2, label: "Natural Gas" },
      { value: 3, label: "Liquified Petroleum Gas (LPG)" },
      { value: 4, label: "Purchased Cooling" },
      { value: 5, label: "Purchased Heating" },
      { value: 6, label: "Purchased Water" },
    ]
    const unitData = [
      { value: 1, label: "kWh" },
      { value: 2, label: "mmBtu" },
      { value: 3, label: "Refrigeration Ton (RT)" },
      { value: 4, label: "kg" },
      { value: 5, label: "Litres" },

    ]
    const numeratorData = [
      { value: 1, label: "10" },
      { value: 2, label: "20" },
      { value: 3, label: "30" },
      { value: 4, label: "40" },
      { value: 5, label: "50" },
    ]
    const facilityDocuments = [
      { value: 1, label: "Floor Plans" },
      { value: 2, label: "Single Line Diagrams" },
      { value: 3, label: "Chilled Water Piping Schematics" },
      { value: 4, label: "Potable Water Piping Schematics" },
    ]
    return (
      <div>
        <div
          className=""
          style={{
            // background: theme == "light" ? Colors.light.active : "#010849",
            // borderRadius: "15px",
            // width: "100%",
            marginLeft: "5px",
            // marginTop: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="col-lg-12 col-12" style={{}}>
              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="col-lg-2 col-2">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px", color: invertSolidDark(theme) }}
                    >
                      Electricity Net Metered:
                    </div>
                  </div>
                </div>
                <div className="col-lg-10 col-10" style={{marginLeft:'20px'}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      gap: "20px"
                    }}
                  >
                     <div
                      style={{ minWidth: "80px", display: "flex", justifyContent: "space-between" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <span style={{ color: invertSolidDark(theme) }} >Yes</span>
                          <Radio
                          sx={{
                            color: "white",
                            '&.Mui-checked': {
                              color: Colors.light.softGreenBg,
                            },
                          }}
                          checked={inputData?.electricNetCheck == "yes"}
                          onChange={(e) =>
                            _handleChangeInputData(
                              "yes",
                              "electricNetCheck"
                            )}
                        />
                        </span>
                      </div>
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <span style={{ color: invertSolidDark(theme) }}>No</span>
                          <Radio
                          sx={{
                            color: "white",
                            '&.Mui-checked': {
                              color: Colors.light.softGreenBg,
                            },
                          }}
                          checked={inputData?.electricNetCheck == "no"}
                          onChange={(e) =>
                            _handleChangeInputData(
                              "no",
                              "electricNetCheck"
                            )}
                        />
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                    <label style={{color:invertSolidDark(theme),marginBottom:'5px'}}>Frequency</label>
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        menuListColor={Colors.Dark[0]}
                        options={consumptionData}
                        value={consumptionData.filter(
                          (v) => v.label === inputData?.electricNetMeteredFre
                        )}
                        placeholder={"Select Options"}
                        _handleSelect={(e) =>
                          _handleChangeInputData(e.label, "electricNetMeteredFre")
                        }
                      />
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                    <label style={{color:invertSolidDark(theme),marginBottom:'5px'}}>Horizon</label>
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        menuListColor={Colors.Dark[0]}
                        options={consumptionData}
                        value={consumptionData.filter(
                          (v) => v.label === inputData?.electricNetMeteredHori
                        )}
                        placeholder={"Select Options"}
                        _handleSelect={(e) =>
                          _handleChangeInputData(e.label, "electricNetMeteredHori")
                        }
                      />
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-3 col-3" style={{ textAlign: "end" }}>
                                                    <div style={{display:"flex",alignItems:"center",justifyContent:"end",gap:"10px",textAlign:"end"}}>
                                                <div
                                                    className="px-4"
                                                    style={{
                                                        width:"200px",
                                                        height:"30px",
                                                        background: Colors.light.darkSoftGreenBg,
                                                        borderRadius: 15,
                                                        cursor: 'pointer',
                                                        textAlign: "center",
                                                        verticalAlign:"middle",
                                                        textOverflow:'ellipsis', 
                                                        whiteSpace:'nowrap', 
                                                        overflow:'hidden',
                                                        color:invertDark(theme)
                                                    }}
                                                    title="Click to preview"
                                                // onClick={() => handleClickToView(row.url, index)}

                                                >
                                                    {/* {electricalLoadDetails?.building1Electrical?.name} */}
                                                    Default.png
                                                </div>
                                                <DownloadIcon style={{color:invertDark(theme)}}/>
                                                
                                            </div>
                                        </div>
                  </div>
                </div>
              </div>
              <div
                className=" mb-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-2 col-2">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px"}}
                    >
                      Natural Gas:
                    </div>
                  </div>
                </div>
                <div className="col-lg-10 col-10" style={{marginLeft:"20px"}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      gap: "20px"
                    }}
                  >
                     <div
                      style={{ minWidth: "80px", display: "flex", justifyContent: "space-between" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <span style={{}} >Yes</span>
                          <Radio
                          sx={{
                            color: "white",
                            '&.Mui-checked': {
                              color: Colors.light.softGreenBg,
                            },
                          }}
                          checked={inputData?.naturalGasCheck == "yes"}
                          onChange={(e) =>
                            _handleChangeInputData(
                              "yes",
                              "naturalGasCheck"
                            )}
                        />
                        </span>
                      </div>
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <span style={{ }}>No</span>
                          <Radio
                          sx={{
                            color: "white",
                            '&.Mui-checked': {
                              color: Colors.light.softGreenBg,
                            },
                          }}
                          checked={inputData?.naturalGasCheck == "no"}
                          onChange={(e) =>
                            _handleChangeInputData(
                              "no",
                              "naturalGasCheck"
                            )}
                        />
                          
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                    <label style={{marginBottom:'5px'}}>Frequency</label>
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        menuListColor={Colors.Dark[0]}
                        options={consumptionData}
                        value={consumptionData.filter(
                          (v) => v.label === inputData?.naturalGasFre
                        )}
                        placeholder={"Select Options"}
                        _handleSelect={(e) =>
                          _handleChangeInputData(e.label, "naturalGasFre")
                        }
                      />
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                    <label style={{marginBottom:'5px'}}>Horizon</label>
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        menuListColor={Colors.Dark[0]}
                        options={consumptionData}
                        value={consumptionData.filter(
                          (v) => v.label === inputData?.naturalGasHori
                        )}
                        placeholder={"Select Options"}
                        _handleSelect={(e) =>
                          _handleChangeInputData(e.label, "naturalGasHori")
                        }
                      />
                    </div>
                <div className="">
                <MyFileInput
                        text=""
                        id={"naturalGasBaselining"}
                        theme={theme}
                        fontSize={"15px"}
                        toolTip ={true}
                        file={inputData?.naturalGasFile}
                        onChange={(e) =>
                          _handleChangeInputData(e?.target?.files[0],"naturalGasFile")
                        }
                      />
                </div>
                <MyButton
                    text={'Upload'}
                    id={"button"}
                    custom ={'custom'}
                    customColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
                    customHoverColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg} />
                  </div>
                </div>
              </div>
              <div
                className=" mb-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-2 col-2">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px"}}
                    >
                      Net DCS(District Cooling System):
                    </div>
                  </div>
                </div>
                <div className="col-lg-10 col-10" style={{marginLeft:"20px"}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      gap: "20px"
                    }}
                  >
                     <div
                      style={{ minWidth: "80px", display: "flex", justifyContent: "space-between" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <span style={{ }} >Yes</span>
                          <Radio
                          sx={{
                            color: "white",
                            '&.Mui-checked': {
                              color: Colors.light.softGreenBg,
                            },
                          }}
                          checked={inputData?.netDCSCheck == "yes"}
                          onChange={(e) =>
                            _handleChangeInputData(
                              "yes",
                              "netDCSCheck"
                            )}
                        />
                        </span>
                      </div>
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <span style={{}}>No</span>
                          <Radio
                          sx={{
                            color: "white",
                            '&.Mui-checked': {
                              color: Colors.light.softGreenBg,
                            },
                          }}
                          checked={inputData?.netDCSCheck == "no"}
                          onChange={(e) =>
                            _handleChangeInputData(
                              "no",
                              "netDCSCheck"
                            )}
                        />
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                    <label style={{marginBottom:'5px'}}>Frequency</label>
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        menuListColor={Colors.Dark[0]}
                        options={consumptionData}
                        value={consumptionData.filter(
                          (v) => v.label === inputData?.NetDCSFre
                        )}
                        placeholder={"Select Options"}
                        _handleSelect={(e) =>
                          _handleChangeInputData(e.label, "NetDCSFre")
                        }
                      />
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                    <label style={{marginBottom:'5px'}}>Horizon</label>
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        menuListColor={Colors.Dark[0]}
                        options={consumptionData}
                        value={consumptionData.filter(
                          (v) => v.label === inputData?.NetDCSHori
                        )}
                        placeholder={"Select Options"}
                        _handleSelect={(e) =>
                          _handleChangeInputData(e.label, "NetDCSHori")
                        }
                      />
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-3 col-3" style={{ textAlign: "end" }}>
                                                    <div style={{display:"flex",alignItems:"center",justifyContent:"end",gap:"10px",textAlign:"end"}}>
                                                <div
                                                    className="px-4"
                                                    style={{
                                                        width:"200px",
                                                        height:"30px",
                                                        background: Colors.light.darkSoftGreenBg,
                                                        borderRadius: 15,
                                                        cursor: 'pointer',
                                                        textAlign: "center",
                                                        verticalAlign:"middle",
                                                        textOverflow:'ellipsis', 
                                                        whiteSpace:'nowrap', 
                                                        overflow:'hidden',
                                                        color:invertDark(theme)
                                                    }}
                                                    title="Click to preview"
                                                >
                                                    {/* {electricalLoadDetails?.building1Electrical?.name} */}
                                                    Default.png
                                                </div>
                                                <DownloadIcon style={{color:invertDark(theme)}}/>
                                                
                                            </div>
                                        </div>
                  </div>
                </div>
              </div>
              <div
                className=" mb-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-2 col-2">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px"}}
                    >
                      H2:
                    </div>
                  </div>
                </div>
                <div className="col-lg-10 col-10" style={{marginLeft:"20px"}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      gap: "20px"
                    }}
                  >
                     <div
                      style={{ minWidth: "80px", display: "flex", justifyContent: "space-between" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <span style={{}} >Yes</span>
                          <Radio
                          sx={{
                            color: "white",
                            '&.Mui-checked': {
                              color: Colors.light.softGreenBg,
                            },
                          }}
                          checked={inputData?.H2Check == "yes"}
                          onChange={(e) =>
                            _handleChangeInputData(
                              "yes",
                              "H2Check"
                            )}
                        />
                        </span>
                      </div>
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <span style={{}}>No</span>
                          <Radio
                          sx={{
                            color: "white",
                            '&.Mui-checked': {
                              color: Colors.light.softGreenBg,
                            },
                          }}
                          checked={inputData?.H2Check == "no"}
                          onChange={(e) =>
                            _handleChangeInputData(
                              "no",
                              "H2Check"
                            )}
                        />
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                    <label style={{marginBottom:'5px'}}>Frequency</label>
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        menuListColor={Colors.Dark[0]}
                        options={consumptionData}
                        value={consumptionData.filter(
                          (v) => v.label === inputData?.H2Fre
                        )}
                        placeholder={"Select Options"}
                        _handleSelect={(e) =>
                          _handleChangeInputData(e.label, "H2Fre")
                        }
                      />
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                    <label style={{marginBottom:'5px'}}>Horizon</label>
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        menuListColor={Colors.Dark[0]}
                        options={consumptionData}
                        value={consumptionData.filter(
                          (v) => v.label === inputData?.H2Hori
                        )}
                        placeholder={"Select Options"}
                        _handleSelect={(e) =>
                          _handleChangeInputData(e.label, "H2Hori")
                        }
                      />
                    </div>
                    <MyFileInput
                        text=""
                        id={"H2Baselining"}
                        theme={theme}
                        fontSize={"15px"}
                        toolTip ={true}
                        file={inputData?.H2File}
                        onChange={(e) =>
                          _handleChangeInputData(e.target.files[0], "H2File")
                        }
                      />
                      <MyButton
                    text={'Upload'}
                    id={"button"}
                    custom ={'custom'}
                    customColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
                    customHoverColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg} />
                  </div>
                </div>
              </div>
              <div
                className=" mb-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-2 col-2">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px"}}
                    >
                      Solar:
                    </div>
                  </div>
                </div>
                <div className="col-lg-10 col-10" style={{marginLeft:"20px"}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      gap: "20px"
                    }}
                  >
                     <div
                      style={{ minWidth: "80px", display: "flex", justifyContent: "space-between" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <span style={{  }} >Yes</span>
                          <Radio
                          sx={{
                            color: "white",
                            '&.Mui-checked': {
                              color: Colors.light.softGreenBg,
                            },
                          }}
                          checked={inputData?.solarCheck == "yes"}
                          onChange={(e) =>
                            _handleChangeInputData(
                              "yes",
                              "solarCheck"
                            )}
                        />
                        </span>
                      </div>
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <span style={{  }}>No</span>
                          <Radio
                          sx={{
                            color: "white",
                            '&.Mui-checked': {
                              color: Colors.light.softGreenBg,
                            },
                          }}
                          checked={inputData?.solarCheck == "no"}
                          onChange={(e) =>
                            _handleChangeInputData(
                              "no",
                              "solarCheck"
                            )}
                        />
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                    <label style={{marginBottom:'5px'}}>Frequency</label>
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        menuListColor={Colors.Dark[0]}
                        options={consumptionData}
                        value={consumptionData.filter(
                          (v) => v.label === inputData?.solarFre
                        )}
                        placeholder={"Select Options"}
                        _handleSelect={(e) =>
                          _handleChangeInputData(e.label, "solarFre")
                        }
                      />
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                    <label style={{marginBottom:'5px'}}>Horizon</label>
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        menuListColor={Colors.Dark[0]}
                        options={consumptionData}
                        value={consumptionData.filter(
                          (v) => v.label === inputData?.solarHori
                        )}
                        placeholder={"Select Options"}
                        _handleSelect={(e) =>
                          _handleChangeInputData(e.label, "solarHori")
                        }
                      />
                    </div>
                    <MyFileInput
                        text=""
                        id={"solarBaselining"}
                        theme={theme}
                        fontSize={"15px"}
                        toolTip ={true}
                        file={inputData?.solarFile}
                        onChange={(e) =>
                          _handleChangeInputData(e.target.files[0], "solarFile")
                        }
                      />
                      <MyButton
                    text={'Upload'}
                    id={"button"}
                    custom ={'custom'}
                    customColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
                    customHoverColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg} />
                  </div>
                </div>
              </div>
              <div
                className=" mb-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-2 col-2">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px" }}
                    >
                      Wind:
                    </div>
                  </div>
                </div>
                <div className="col-lg-10 col-10" style={{marginLeft:"20px"}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      gap: "20px"
                    }}
                  >
                     <div
                      style={{ minWidth: "80px", display: "flex", justifyContent: "space-between" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <span style={{  }} >Yes</span>
                          <Radio
                          sx={{
                            color: "white",
                            '&.Mui-checked': {
                              color: Colors.light.softGreenBg,
                            },
                          }}
                          checked={inputData?.windCheck == "yes"}
                          onChange={(e) =>
                            _handleChangeInputData(
                              "yes",
                              "windCheck"
                            )}
                        />
                        </span>
                      </div>
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <span style={{  }}>No</span>
                          <Radio
                          sx={{
                            color: "white",
                            '&.Mui-checked': {
                              color: Colors.light.softGreenBg,
                            },
                          }}
                          checked={inputData?.windCheck == "no"}
                          onChange={(e) =>
                            _handleChangeInputData(
                              "no",
                              "windCheck"
                            )}
                        />
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                      <label style={{marginBottom:'5px'}}>Frequency</label>
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        menuListColor={Colors.Dark[0]}
                        options={consumptionData}
                        value={consumptionData.filter(
                          (v) => v.label === inputData?.windFre
                        )}
                        placeholder={"Select Options"}
                        _handleSelect={(e) =>
                          _handleChangeInputData(e.label, "windFre")
                        }
                      />
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                    <label style={{marginBottom:'5px'}}>Horizon</label>
                      <Selector
                        getOptionLabel={(e) => (
                          <div style={{ display: "flex", textAlign: "left" }}>
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                        menuListColor={Colors.Dark[0]}
                        options={consumptionData}
                        value={consumptionData.filter(
                          (v) => v.label === inputData?.windHori
                        )}
                        placeholder={"Select Options"}
                        _handleSelect={(e) =>
                          _handleChangeInputData(e.label, "windHori")
                        }
                      />
                    </div>
                    <MyFileInput
                        text=""
                        id={"windBaselining"}
                        theme={theme}
                        fontSize={"15px"}
                        toolTip ={true}
                        file={inputData?.windFile}
                        onChange={(e) =>
                          _handleChangeInputData(e.target.files[0], "windFile")
                        }
                      />
                      <MyButton
                    text={'Upload'}
                    id={"button"}
                    custom ={'custom'}
                    customColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
                    customHoverColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg} />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  })
);
