import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Colors, invertDark, invertSolidDark } from '../../Config/colors.config';
import { MyButton } from '../../Tools/MyButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import EastIcon from '@mui/icons-material/East';
import { IconButton, Collapse, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import HelpIcon from '@mui/icons-material/Help';
import Radio from '@mui/material/Radio';

export const Page4 = inject('store')(
	observer((props) => {
		const { theme } = props.store.common;
		const { inputData, _handleChangeInputData } = props.store.timeseries;

		const HtmlTooltip = styled(({ className, ...props }) => (
			<Tooltip
				{...props}
				classes={{ popper: className }}
				placement="right-start"
				arrow
			/>
		))(({ theme }) => ({
			[`& .${tooltipClasses.tooltip}`]: {
				backgroundColor: '#f5f5f9',
				color: 'rgba(0, 0, 0, 0.87)',
				minWidth: 380,
				fontSize: theme.typography.pxToRem(25),
				border: '1px solid #dadde9',
			},
			[`& .${tooltipClasses.arrow}`]: {
				color: '#f5f5f9',
			},
		}));
		const tempData = [
			{ value: 1, label: 'Every 15 minutes' },
			{ value: 2, label: 'Every 30 minutes' },
			{ value: 3, label: 'Hourly' },
			{ value: 4, label: 'Daily' },
			{ value: 5, label: 'Weekly' },
			{ value: 6, label: 'Monthly' },
		];
		const consumptionData = [
			{ value: 1, label: 'Electricity' },
			{ value: 2, label: 'Natural Gas' },
			{ value: 3, label: 'Liquified Petroleum Gas (LPG)' },
			{ value: 4, label: 'Purchased Cooling' },
			{ value: 5, label: 'Purchased Heating' },
			{ value: 6, label: 'Purchased Water' },
		];
		const unitData = [
			{ value: 1, label: 'kWh' },
			{ value: 2, label: 'mmBtu' },
			{ value: 3, label: 'Refrigeration Ton (RT)' },
			{ value: 4, label: 'kg' },
			{ value: 5, label: 'Litres' },
		];
		const numeratorData = [
			{ value: 1, label: '10' },
			{ value: 2, label: '20' },
			{ value: 3, label: '30' },
			{ value: 4, label: '40' },
			{ value: 5, label: '50' },
		];
		const facilityDocuments = [
			{ value: 1, label: 'Floor Plans' },
			{ value: 2, label: 'Single Line Diagrams' },
			{ value: 3, label: 'Chilled Water Piping Schematics' },
			{ value: 4, label: 'Potable Water Piping Schematics' },
		];
		return (
			<div>
				<div
					className=""
					style={{
						// background: theme == "light" ? Colors.light.active : "#010849",
						// borderRadius: "15px",
						// width: "100%",
						marginLeft: '5px',
						// marginTop: "10px",
					}}
				>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div
							className="col-lg-12 col-12"
							style={{}}
						>
							<div
								className=" mb-3 mt-3"
								style={{ display: 'flex', alignItems: 'center', color: invertSolidDark(theme) }}
							>
								<div className="col-lg-3 col-3">
									<div className=" d-flex">
										<div
											className="col"
											style={{ marginTop: '20px', marginLeft: '15px' }}
										>
											PV{' '}
											<HtmlTooltip
												title={
													<React.Fragment>
														<Typography fontSize={14}>{'PV'}</Typography>
													</React.Fragment>
												}
											>
												<HelpIcon
													//   onClick={(e) => e.stopPropagation()}
													style={{
														fill: theme === 'dark' ? '#ddd' : '#1565c0',
														marginLeft: '8px',
													}}
												/>
											</HtmlTooltip>
										</div>
									</div>
								</div>
								<div
									className="col-lg-9 col-9"
									style={{ marginLeft: '20px' }}
								>
									<div
										className=""
										style={{
											display: 'flex',
											alignItems: 'end',
											gap: '20px',
										}}
									>
										<div
											style={{
												minWidth: '80px',
												display: 'flex',
												justifyContent: 'space-between',
												marginRight: '20px',
											}}
										>
											<div>
												<span
													style={{
														minHeight: '50px',
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center',
														justifyContent: 'space-around',
													}}
												>
													<span style={{}}>Yes</span>
													<Radio
														sx={{
															color: 'white',
															'&.Mui-checked': {
																color: Colors.light.softGreenBg,
															},
														}}
														checked={inputData?.PVCheck == 'yes'}
														onChange={(e) => _handleChangeInputData('yes', 'PVCheck')}
													/>
												</span>
											</div>
											<div className="">
												<span
													style={{
														minHeight: '50px',
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center',
														justifyContent: 'space-around',
													}}
												>
													<span style={{}}>No</span>
													<Radio
														sx={{
															color: 'white',
															'&.Mui-checked': {
																color: Colors.light.softGreenBg,
															},
														}}
														checked={inputData?.PVCheck == 'no'}
														onChange={(e) => _handleChangeInputData('no', 'PVCheck')}
													/>
												</span>
											</div>
										</div>
										<MyButton
											text={'Describe'}
											rightIcon={<EastIcon />}
											id={'button'}
											custom={'custom'}
											customColor={theme === 'dark' ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
											customHoverColor={theme === 'dark' ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
										/>
									</div>
								</div>
							</div>
							<div
								className=" mb-3 mt-3"
								style={{ display: 'flex', alignItems: 'center', color: invertSolidDark(theme) }}
							>
								<div className="col-lg-3 col-3">
									<div className=" d-flex">
										<div
											className="col"
											style={{ marginTop: '20px', marginLeft: '15px' }}
										>
											Battery{' '}
											<HtmlTooltip
												title={
													<React.Fragment>
														<Typography fontSize={14}>{'Battery'}</Typography>
													</React.Fragment>
												}
											>
												<HelpIcon
													//   onClick={(e) => e.stopPropagation()}
													style={{
														fill: theme === 'dark' ? '#ddd' : '#1565c0',
														marginLeft: '8px',
													}}
												/>
											</HtmlTooltip>
										</div>
									</div>
								</div>
								<div
									className="col-lg-9 col-9"
									style={{ marginLeft: '20px' }}
								>
									<div
										className=""
										style={{
											display: 'flex',
											alignItems: 'end',
											gap: '20px',
										}}
									>
										<div
											style={{
												minWidth: '80px',
												display: 'flex',
												justifyContent: 'space-between',
												marginRight: '20px',
											}}
										>
											<div>
												<span
													style={{
														minHeight: '50px',
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center',
														justifyContent: 'space-around',
													}}
												>
													<span style={{}}>Yes</span>
													<Radio
														sx={{
															color: 'white',
															'&.Mui-checked': {
																color: Colors.light.softGreenBg,
															},
														}}
														checked={inputData?.batteryCheck == 'yes'}
														onChange={(e) => _handleChangeInputData('yes', 'batteryCheck')}
													/>
												</span>
											</div>
											<div className="">
												<span
													style={{
														minHeight: '50px',
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center',
														justifyContent: 'space-around',
													}}
												>
													<span style={{}}>No</span>
													<Radio
														sx={{
															color: 'white',
															'&.Mui-checked': {
																color: Colors.light.softGreenBg,
															},
														}}
														checked={inputData?.batteryCheck == 'no'}
														onChange={(e) => _handleChangeInputData('no', 'batteryCheck')}
													/>
												</span>
											</div>
										</div>
										<MyButton
											text={'Describe'}
											rightIcon={<EastIcon />}
											id={'button'}
											custom={'custom'}
											customColor={theme === 'dark' ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
											customHoverColor={theme === 'dark' ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
										/>
									</div>
								</div>
							</div>
							<div
								className=" mb-3 mt-3"
								style={{ display: 'flex', alignItems: 'center', color: invertSolidDark(theme) }}
							>
								<div className="col-lg-3 col-3">
									<div className=" d-flex">
										<div
											className="col"
											style={{ marginTop: '20px', marginLeft: '15px' }}
										>
											Thermal Storage{' '}
											<HtmlTooltip
												title={
													<React.Fragment>
														<Typography fontSize={14}>{'Thermal Storage'}</Typography>
													</React.Fragment>
												}
											>
												<HelpIcon
													//   onClick={(e) => e.stopPropagation()}
													style={{
														fill: theme === 'dark' ? '#ddd' : '#1565c0',
														marginLeft: '8px',
													}}
												/>
											</HtmlTooltip>
										</div>
									</div>
								</div>
								<div
									className="col-lg-9 col-9"
									style={{ marginLeft: '20px' }}
								>
									<div
										className=""
										style={{
											display: 'flex',
											alignItems: 'end',
											gap: '20px',
										}}
									>
										<div
											style={{
												minWidth: '80px',
												display: 'flex',
												justifyContent: 'space-between',
												marginRight: '20px',
											}}
										>
											<div>
												<span
													style={{
														minHeight: '50px',
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center',
														justifyContent: 'space-around',
													}}
												>
													<span style={{}}>Yes</span>
													<Radio
														sx={{
															color: 'white',
															'&.Mui-checked': {
																color: Colors.light.softGreenBg,
															},
														}}
														checked={inputData?.thermalStorageCheck == 'yes'}
														onChange={(e) => _handleChangeInputData('yes', 'thermalStorageCheck')}
													/>
												</span>
											</div>
											<div className="">
												<span
													style={{
														minHeight: '50px',
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center',
														justifyContent: 'space-around',
													}}
												>
													<span style={{}}>No</span>
													<Radio
														sx={{
															color: 'white',
															'&.Mui-checked': {
																color: Colors.light.softGreenBg,
															},
														}}
														checked={inputData?.thermalStorageCheck == 'no'}
														onChange={(e) => _handleChangeInputData('no', 'thermalStorageCheck')}
													/>
												</span>
											</div>
										</div>
										<MyButton
											text={'Describe'}
											rightIcon={<EastIcon />}
											id={'button'}
											custom={'custom'}
											customColor={theme === 'dark' ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
											customHoverColor={theme === 'dark' ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
										/>
									</div>
								</div>
							</div>
							<div
								className=" mb-3 mt-3"
								style={{ display: 'flex', alignItems: 'center', color: invertSolidDark(theme) }}
							>
								<div className="col-lg-3 col-3">
									<div className=" d-flex">
										<div
											className="col"
											style={{ marginTop: '20px', marginLeft: '15px' }}
										>
											Genset{' '}
											<HtmlTooltip
												title={
													<React.Fragment>
														<Typography fontSize={14}>{'Genset'}</Typography>
													</React.Fragment>
												}
											>
												<HelpIcon
													//   onClick={(e) => e.stopPropagation()}
													style={{
														fill: theme === 'dark' ? '#ddd' : '#1565c0',
														marginLeft: '8px',
													}}
												/>
											</HtmlTooltip>
										</div>
									</div>
								</div>
								<div
									className="col-lg-9 col-9"
									style={{ marginLeft: '20px' }}
								>
									<div
										className=""
										style={{
											display: 'flex',
											alignItems: 'end',
											gap: '20px',
										}}
									>
										<div
											style={{
												minWidth: '80px',
												display: 'flex',
												justifyContent: 'space-between',
												marginRight: '20px',
											}}
										>
											<div>
												<span
													style={{
														minHeight: '50px',
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center',
														justifyContent: 'space-around',
													}}
												>
													<span style={{}}>Yes</span>
													<Radio
														sx={{
															color: 'white',
															'&.Mui-checked': {
																color: Colors.light.softGreenBg,
															},
														}}
														checked={inputData?.gensetCheck == 'yes'}
														onChange={(e) => _handleChangeInputData('yes', 'gensetCheck')}
													/>
												</span>
											</div>
											<div className="">
												<span
													style={{
														minHeight: '50px',
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center',
														justifyContent: 'space-around',
													}}
												>
													<span style={{}}>No</span>
													<Radio
														sx={{
															color: 'white',
															'&.Mui-checked': {
																color: Colors.light.softGreenBg,
															},
														}}
														checked={inputData?.gensetCheck == 'no'}
														onChange={(e) => _handleChangeInputData('no', 'gensetCheck')}
													/>
												</span>
											</div>
										</div>
										<MyButton
											text={'Describe'}
											rightIcon={<EastIcon />}
											id={'button'}
											custom={'custom'}
											customColor={theme === 'dark' ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
											customHoverColor={theme === 'dark' ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
										/>
									</div>
								</div>
							</div>
							<div
								className=" mb-3 mt-3"
								style={{ display: 'flex', alignItems: 'center', color: invertSolidDark(theme) }}
							>
								<div className="col-lg-3 col-3">
									<div className=" d-flex">
										<div
											className="col"
											style={{ marginTop: '20px', marginLeft: '15px' }}
										>
											CHP{' '}
											<HtmlTooltip
												title={
													<React.Fragment>
														<Typography fontSize={14}>{'CHP'}</Typography>
													</React.Fragment>
												}
											>
												<HelpIcon
													//   onClick={(e) => e.stopPropagation()}
													style={{
														fill: theme === 'dark' ? '#ddd' : '#1565c0',
														marginLeft: '8px',
													}}
												/>
											</HtmlTooltip>
										</div>
									</div>
								</div>
								<div
									className="col-lg-9 col-9"
									style={{ marginLeft: '20px' }}
								>
									<div
										className=""
										style={{
											display: 'flex',
											alignItems: 'end',
											gap: '20px',
										}}
									>
										<div
											style={{
												minWidth: '80px',
												display: 'flex',
												justifyContent: 'space-between',
												marginRight: '20px',
											}}
										>
											<div>
												<span
													style={{
														minHeight: '50px',
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center',
														justifyContent: 'space-around',
													}}
												>
													<span style={{}}>Yes</span>
													<Radio
														sx={{
															color: 'white',
															'&.Mui-checked': {
																color: Colors.light.softGreenBg,
															},
														}}
														checked={inputData?.CHPCheckCHPCheck == 'yes'}
														onChange={(e) => _handleChangeInputData('yes', 'CHPCheckCHPCheck')}
													/>
												</span>
											</div>
											<div className="">
												<span
													style={{
														minHeight: '50px',
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center',
														justifyContent: 'space-around',
													}}
												>
													<span style={{}}>No</span>
													<Radio
														sx={{
															color: 'white',
															'&.Mui-checked': {
																color: Colors.light.softGreenBg,
															},
														}}
														checked={inputData?.CHPCheckCHPCheck == 'no'}
														onChange={(e) => _handleChangeInputData('no', 'CHPCheckCHPCheck')}
													/>
												</span>
											</div>
										</div>
										<MyButton
											text={'Describe'}
											rightIcon={<EastIcon />}
											id={'button'}
											custom={'custom'}
											customColor={theme === 'dark' ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
											customHoverColor={theme === 'dark' ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
										/>
									</div>
								</div>
							</div>
							<div
								className=" mb-3 mt-3"
								style={{ display: 'flex', alignItems: 'center', color: invertSolidDark(theme) }}
							>
								<div className="col-lg-3 col-3">
									<div className=" d-flex">
										<div
											className="col"
											style={{ marginTop: '20px', marginLeft: '15px' }}
										>
											Cooling Import Capacity{' '}
											<HtmlTooltip
												title={
													<React.Fragment>
														<Typography fontSize={14}>{'Cooling Import Capacity'}</Typography>
													</React.Fragment>
												}
											>
												<HelpIcon
													//   onClick={(e) => e.stopPropagation()}
													style={{
														fill: theme === 'dark' ? '#ddd' : '#1565c0',
														marginLeft: '8px',
													}}
												/>
											</HtmlTooltip>
										</div>
									</div>
								</div>
								<div
									className="col-lg-9 col-9"
									style={{ marginLeft: '20px' }}
								>
									<div
										className=""
										style={{
											display: 'flex',
											alignItems: 'end',
											gap: '20px',
										}}
									>
										<div
											style={{
												minWidth: '80px',
												display: 'flex',
												justifyContent: 'space-between',
												marginRight: '20px',
											}}
										>
											<div>
												<span
													style={{
														minHeight: '50px',
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center',
														justifyContent: 'space-around',
													}}
												>
													<span style={{}}>Yes</span>
													<Radio
														sx={{
															color: 'white',
															'&.Mui-checked': {
																color: Colors.light.softGreenBg,
															},
														}}
														checked={inputData?.coolingCapCheck == 'yes'}
														onChange={(e) => _handleChangeInputData('yes', 'coolingCapCheck')}
													/>
												</span>
											</div>
											<div className="">
												<span
													style={{
														minHeight: '50px',
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center',
														justifyContent: 'space-around',
													}}
												>
													<span style={{}}>No</span>
													<Radio
														sx={{
															color: 'white',
															'&.Mui-checked': {
																color: Colors.light.softGreenBg,
															},
														}}
														checked={inputData?.coolingCapCheck == 'no'}
														onChange={(e) => _handleChangeInputData('no', 'coolingCapCheck')}
													/>
												</span>
											</div>
										</div>
										<MyButton
											text={'Describe'}
											rightIcon={<EastIcon />}
											id={'button'}
											custom={'custom'}
											customColor={theme === 'dark' ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
											customHoverColor={theme === 'dark' ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}),
);
