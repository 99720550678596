import { inject, observer } from "mobx-react";
import * as React from "react";
import { Selector } from "../../Tools/MySelector";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Colors, invertDark, invertSolidDark } from "../../Config/colors.config";
import Checkbox from '@mui/material/Checkbox';
import { MyFileInput } from "../../Tools/MyFileInput";
import { MyButton } from "../../Tools/MyButton";

export const Page3 = inject("store")(
    observer((props) => {
        const { theme } = props.store.common;
        const { _handleChangeInputData, inputData, handlecheck} = props.store.timeseries;

        const HtmlTooltip = styled(({ className, ...props }) => (
            <Tooltip
                {...props}
                classes={{ popper: className }}
                placement="right-start"
                arrow
            />
        ))(({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: "#f5f5f9",
                color: "rgba(0, 0, 0, 0.87)",
                minWidth: 380,
                fontSize: theme.typography.pxToRem(25),
                border: "1px solid #dadde9",
            },
            [`& .${tooltipClasses.arrow}`]: {
                color: "#f5f5f9",
            },
        }));
        const tempData = [
            { value: 1, label: "Every 15 minutes" },
            { value: 2, label: "Every 30 minutes" },
            { value: 3, label: "Hourly" },
            { value: 4, label: "Daily" },
            { value: 5, label: "Weekly" },
            { value: 6, label: "Monthly" },
        ];
        const consumptionData = [
            { value: 1, label: "Electricity" },
            { value: 2, label: "Natural Gas" },
            { value: 3, label: "Liquified Petroleum Gas (LPG)" },
            { value: 4, label: "Purchased Cooling" },
            { value: 5, label: "Purchased Heating" },
            { value: 6, label: "Purchased Water" },
        ]
        const unitData = [
            { value: 1, label: "kWh" },
            { value: 2, label: "mmBtu" },
            { value: 3, label: "Refrigeration Ton (RT)" },
            { value: 4, label: "kg" },
            { value: 5, label: "Litres" },

        ]
        const numeratorData = [
            { value: 1, label: "10" },
            { value: 2, label: "20" },
            { value: 3, label: "30" },
            { value: 4, label: "40" },
            { value: 5, label: "50" },
        ]
        const facilityDocuments = [
            { value: 1, label: "Floor Plans" },
            { value: 2, label: "Single Line Diagrams" },
            { value: 3, label: "Chilled Water Piping Schematics" },
            { value: 4, label: "Potable Water Piping Schematics" },
        ]
        return (
            <div>
                <div
                    className=""
                    style={{
                        marginLeft: "5px",
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="col-lg-12 col-12" style={{}}>

                            <div
                                className=" mb-3 mt-3"
                                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
                            >
                                <div className="col-lg-2 col-2">
                                    <div className=" d-flex">
                                        <div
                                            className="col"
                                            style={{ marginTop: "20px", marginLeft: "15px" }}
                                        >
                                            Electricity price:
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-10 col-10" style={{ marginLeft: "20px" }}>
                                    <div
                                        className=""
                                        style={{
                                            display: "flex",
                                            alignItems: "end",
                                            gap: "20px"
                                        }}
                                    >
                                        <div
                                            style={{ minWidth: "80px", display: "flex", justifyContent: "space-between" }}
                                        >
                                            <div>
                                                <span
                                                    style={{
                                                        minHeight: "50px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "space-around",         
                                                    }}
                                                >
                                                    <span className="" style={{}} >Standard</span>
                                                    <Checkbox                                                     
                                                        checked={inputData.electPriceStandard ? inputData.electPriceStandard : false}
                                                        onChange={()=>handlecheck(inputData.electPriceStandard ? inputData.electPriceStandard : false, "electPriceStandard")}
                                                        sx={{
                                                            color: "white",
                                                            '&.Mui-checked': {
                                                                color: "green",
                                                            },
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                           
                                        </div>
                                        <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                            <label style={{  marginBottom: '5px' }}>Frequency</label>
                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>
                                                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                    </div>
                                                )}
                                                menuListColor={Colors.Dark[0]}
                                                options={consumptionData}
                                                value={consumptionData.filter(
                                                    (v) => v.label === inputData?.electPriceFrequency
                                                )}
                                                placeholder={"Every minute"}
                                                _handleSelect={(e) =>
                                                    _handleChangeInputData(e.label, "electPriceFrequency")
                                                }
                                            />
                                        </div>
                                        <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                            <label style={{  marginBottom: '5px' }}>Horizon</label>
                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>
                                                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                    </div>
                                                )}
                                                menuListColor={Colors.Dark[0]}
                                                options={consumptionData}
                                                value={consumptionData.filter(
                                                    (v) => v.label === inputData?.electPriceHorizon
                                                )}
                                                placeholder={"365 dayz"}
                                                _handleSelect={(e) =>
                                                    _handleChangeInputData(e.label, "electPriceHorizon")
                                                }
                                            />
                                        </div>
                                        <div className="">
                                        <MyFileInput
                                            text=""
                                            id={"elecPriceFile"}
                                            theme={theme}
                                            fontSize={"15px"}
                                            toolTip={true}
                                        file={inputData?.elecPriceFile}
                                        onChange={(e) =>
                                          _handleChangeInputData(e.target.files[0], "elecPriceFile")
                                        }
                                        />
                                    </div>
                                    <MyButton
                                        text={'Upload'}
                                        id={"button"}
                                        custom={'custom'}
                                        customColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
                                        customHoverColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg} />

                                    </div>
                                </div>
                            </div>

                            <div
                                className=" mb-3"
                                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
                            >
                                <div className="col-lg-2 col-2">
                                    <div className=" d-flex">
                                        <div
                                            className="col"
                                            style={{ marginTop: "20px", marginLeft: "15px" }}
                                        >
                                            Gas price:
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-10 col-10" style={{ marginLeft: "20px" }}>
                                    <div
                                        className=""
                                        style={{
                                            display: "flex",
                                            alignItems: "end",
                                            gap: "20px"
                                        }}
                                    >
                                        <div
                                            style={{ minWidth: "80px", display: "flex", justifyContent: "space-between" }}
                                        >
                                            <div>
                                                <span
                                                    style={{
                                                        minHeight: "50px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "space-around",
                                                    }}
                                                >
                                                    <span className="">Standard</span>
                                                    <Checkbox                                                     
                                                        checked={inputData.gasPriceStandard ? inputData.gasPriceStandard : false}
                                                        onChange={()=>handlecheck(inputData.gasPriceStandard ? inputData.gasPriceStandard : false, "gasPriceStandard")}
                                                        sx={{
                                                            color: "white",
                                                            '&.Mui-checked': {
                                                                color: "green",
                                                            },
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                           
                                        </div>
                                        <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                            <label style={{  marginBottom: '5px' }}>Frequency</label>
                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>
                                                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                    </div>
                                                )}
                                                menuListColor={Colors.Dark[0]}
                                                options={consumptionData}
                                                value={consumptionData.filter(
                                                    (v) => v.label === inputData?.gasPriceFrequency
                                                )}
                                                placeholder={"Every minute"}
                                                _handleSelect={(e) =>
                                                    _handleChangeInputData(e.label, "gasPriceFrequency")
                                                }
                                            />
                                        </div>
                                        <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                            <label style={{  marginBottom: '5px' }}>Horizon</label>
                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>
                                                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                    </div>
                                                )}
                                                menuListColor={Colors.Dark[0]}
                                                options={consumptionData}
                                                value={consumptionData.filter(
                                                    (v) => v.label === inputData?.gasPriceHorizon
                                                )}
                                                placeholder={"365 day"}
                                                _handleSelect={(e) =>
                                                    _handleChangeInputData(e.label, "gasPriceHorizon")
                                                }
                                            />
                                        </div>
                                        <div className="">
                                        <MyFileInput
                                            text=""
                                            id={"gasPriceFile"}
                                            theme={theme}
                                            fontSize={"15px"}
                                            toolTip={true}
                                        file={inputData?.gasPriceFile}
                                        onChange={(e) =>
                                          _handleChangeInputData(e.target.files[0], "gasPriceFile")
                                        }
                                        />
                                    </div>
                                    <MyButton
                                        text={'Upload'}
                                        id={"button"}
                                        custom={'custom'}
                                        customColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
                                        customHoverColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg} />
                                    </div>
                                </div>
                            </div>

                            <div
                                className=" mb-3"
                                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
                            >
                                <div className="col-lg-2 col-2">
                                    <div className=" d-flex">
                                        <div
                                            className="col"
                                            style={{ marginTop: "20px", marginLeft: "15px" }}
                                        >
                                            H2 price:
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-10 col-10" style={{ marginLeft: "20px" }}>
                                    <div
                                        className=""
                                        style={{
                                            display: "flex",
                                            alignItems: "end",
                                            gap: "20px"
                                        }}
                                    >
                                        <div
                                            style={{ minWidth: "80px", display: "flex", justifyContent: "space-between" }}
                                        >
                                            <div>
                                                <span
                                                    style={{
                                                        minHeight: "50px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "space-around",
                                                       
                                                    }}
                                                >
                                                    <span className="">Standard</span>
                                                    <Checkbox                                                     
                                                        checked={inputData.h2PriceStandard ? inputData.h2PriceStandard : false}
                                                        onChange={()=>handlecheck(inputData.h2PriceStandard ? inputData.h2PriceStandard : false, "h2PriceStandard")}
                                                        sx={{
                                                            color: "white",
                                                            '&.Mui-checked': {
                                                                color: "green",
                                                            },
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                           
                                        </div>
                                        <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                            <label style={{  marginBottom: '5px' }}>Frequency</label>

                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>
                                                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                    </div>
                                                )}
                                                menuListColor={Colors.Dark[0]}
                                                options={consumptionData}
                                                value={consumptionData.filter(
                                                    (v) => v.label === inputData?.h2PriceFrequency
                                                )}
                                                placeholder={"Every minute"}
                                                _handleSelect={(e) =>
                                                    _handleChangeInputData(e.label, "h2PriceFrequency")
                                                }
                                            />
                                        </div>
                                        <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                            <label style={{  marginBottom: '5px' }}>Horizon</label>

                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>
                                                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                    </div>
                                                )}
                                                menuListColor={Colors.Dark[0]}
                                                options={consumptionData}
                                                value={consumptionData.filter(
                                                    (v) => v.label === inputData?.h2PriceHorizon
                                                )}
                                                placeholder={"365 day"}
                                                _handleSelect={(e) =>
                                                    _handleChangeInputData(e.label, "h2PriceHorizon")
                                                }
                                            />
                                        </div>
                                        <div className="">
                                        <MyFileInput
                                            text=""
                                            id={"H2PriceFile"}
                                            theme={theme}
                                            fontSize={"15px"}
                                            toolTip={true}
                                        file={inputData?.H2PriceFile}
                                        onChange={(e) =>
                                          _handleChangeInputData(e.target.files[0], "H2PriceFile")
                                        }
                                        />
                                    </div>
                                    <MyButton
                                        text={'Upload'}
                                        id={"button"}
                                        custom={'custom'}
                                        customColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
                                        customHoverColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg} />
                                    </div>
                                </div>
                            </div>
                            <div
                                className=" mb-3 mt-3"
                                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
                            >
                                <div className="col-lg-2 col-2">
                                    <div className=" d-flex">
                                        <div
                                            className="col"
                                            style={{ marginTop: "20px", marginLeft: "15px" }}
                                        >
                                            Thermal cooling price:
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-10 col-10" style={{ marginLeft: "20px" }}>
                                    <div
                                        className=""
                                        style={{
                                            display: "flex",
                                            alignItems: "end",
                                            gap: "20px"
                                        }}
                                    >
                                        <div
                                            style={{ minWidth: "80px", display: "flex", justifyContent: "space-between" }}
                                        >
                                            <div>
                                                <span
                                                    style={{
                                                        minHeight: "50px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "space-around",
                                                        
                                                    }}
                                                >
                                                    <span className="">Standard</span>
                                                    <Checkbox                                                     
                                                     checked={inputData.thermalPriceStandard ? inputData.thermalPriceStandard : false}
                                                     onChange={()=>handlecheck(inputData.thermalPriceStandard ? inputData.thermalPriceStandard : false, "thermalPriceStandard")}   
                                                        sx={{
                                                            color: "white",
                                                            '&.Mui-checked': {
                                                                color: "green",
                                                            },
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                           
                                        </div>
                                        <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                            <label style={{  marginBottom: '5px' }}>Frequency</label>
                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>
                                                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                    </div>
                                                )}
                                                menuListColor={Colors.Dark[0]}
                                                options={consumptionData}
                                                value={consumptionData.filter(
                                                    (v) => v.label === inputData?.thermalPriceFrequency
                                                )}
                                                placeholder={"Every minute"}
                                                _handleSelect={(e) =>
                                                    _handleChangeInputData(e.label, "thermalPriceFrequency")
                                                }
                                            />
                                        </div>
                                        <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                            <label style={{  marginBottom: '5px' }}>Horizon</label>
                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>
                                                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                    </div>
                                                )}
                                                menuListColor={Colors.Dark[0]}
                                                options={consumptionData}
                                                value={consumptionData.filter(
                                                    (v) => v.label === inputData?.thermalPriceHorizon
                                                )}
                                                placeholder={"365 dayz"}
                                                _handleSelect={(e) =>
                                                    _handleChangeInputData(e.label, "thermalPriceHorizon")
                                                }
                                            />
                                        </div>
                                        <div className="">
                                        <MyFileInput
                                            text=""
                                            id={"thercoolPriceFile"}
                                            theme={theme}
                                            fontSize={"15px"}
                                            toolTip={true}
                                        file={inputData?.thercoolPriceFile}
                                        onChange={(e) =>
                                          _handleChangeInputData(e.target.files[0], "thercoolPriceFile")
                                        }
                                        />
                                    </div>
                                    <MyButton
                                        text={'Upload'}
                                        id={"button"}
                                        custom={'custom'}
                                        customColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
                                        customHoverColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg} />

                                    </div>
                                </div>
                            </div>

                            <div
                                className=" mb-3"
                                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
                            >
                                <div className="col-lg-2 col-2">
                                    <div className=" d-flex">
                                        <div
                                            className="col"
                                            style={{ marginTop: "20px", marginLeft: "15px", color: theme == "light" ? Colors.light.textColor : Colors.light.secBorderColor }}
                                        >
                                            Water price:
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-10 col-10" style={{ marginLeft: "20px" }}>
                                    <div
                                        className=""
                                        style={{
                                            display: "flex",
                                            alignItems: "end",
                                            gap: "20px"
                                        }}
                                    >
                                        <div
                                            style={{ minWidth: "80px", display: "flex", justifyContent: "space-between" }}
                                        >
                                            <div>
                                                <span
                                                    style={{
                                                        minHeight: "50px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "space-around",
                                                        
                                                    }}
                                                >
                                                    <span className="" style={{}} >Standard</span>
                                                    <Checkbox                                                     
                                                       checked={inputData.waterPriceStandard ? inputData.waterPriceStandard : false}
                                                       onChange={()=>handlecheck(inputData.waterPriceStandard ? inputData.waterPriceStandard : false, "waterPriceStandard")}   
                                                        sx={{
                                                            color: "white",
                                                            '&.Mui-checked': {
                                                                color: "green",
                                                            },
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                           
                                        </div>

                                        <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                            <label style={{ marginBottom: '5px' }}>Frequency</label>
                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>
                                                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                    </div>
                                                )}
                                                menuListColor={Colors.Dark[0]}
                                                options={consumptionData}
                                                value={consumptionData.filter(
                                                    (v) => v.label === inputData?.waterPriceFrequency
                                                )}
                                                placeholder={"Every minute"}
                                                _handleSelect={(e) =>
                                                    _handleChangeInputData(e.label, "waterPriceFrequency")
                                                }
                                            />
                                        </div>
                                        <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                            <label style={{ marginBottom: '5px' }}>Horizon</label>
                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>
                                                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                    </div>
                                                )}
                                                menuListColor={Colors.Dark[0]}
                                                options={consumptionData}
                                                value={consumptionData.filter(
                                                    (v) => v.label === inputData?.waterPriceHorizon
                                                )}
                                                placeholder={"365 day"}
                                                _handleSelect={(e) =>
                                                    _handleChangeInputData(e.label, "waterPriceHorizon")
                                                }
                                            />
                                        </div>
                                        <div className="">
                                        <MyFileInput
                                            text=""
                                            id={"waterPriceFile"}
                                            theme={theme}
                                            fontSize={"15px"}
                                            toolTip={true}
                                        file={inputData?.waterPriceFile}
                                        onChange={(e) =>
                                          _handleChangeInputData(e.target.files[0], "waterPriceFile")
                                        }
                                        />
                                    </div>
                                    <MyButton
                                        text={'Upload'}
                                        id={"button"}
                                        custom={'custom'}
                                        customColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
                                        customHoverColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg} />
                                    </div>
                                </div>
                            </div>

                            <div
                                className=" mb-3"
                                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
                            >
                                <div className="col-lg-2 col-2">
                                    <div className=" d-flex">
                                        <div
                                            className="col"
                                            style={{ marginTop: "20px", marginLeft: "15px", color: theme == "light" ? Colors.light.textColor : Colors.light.secBorderColor }}
                                        >
                                            Weather (Solar,Temperature):
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-10 col-10" style={{ marginLeft: "20px"}}>
                                    <div
                                        className="col-12"
                                        style={{
                                            display: "flex",
                                            alignItems: "end",
                                            gap: "20px"
                                        }}
                                    >
                                    <div
                                            style={{ minWidth: "80px", display: "flex", justifyContent: "space-between" }}
                                        >
                                            <div>
                                                <span
                                                    style={{
                                                        minHeight: "50px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "space-around",
                                                       
                                                    }}
                                                >
                                                    <span className="" style={{}} >Standard</span>
                                                    <Checkbox                                                     
                                                     checked={inputData.weatherPriceStandard ? inputData.weatherPriceStandard : false}
                                                     onChange={()=>handlecheck(inputData.weatherPriceStandard ? inputData.weatherPriceStandard : false, "weatherPriceStandard")} 
                                                        sx={{
                                                            color: "white",
                                                            '&.Mui-checked': {
                                                                color: "green",
                                                            },
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                           
                                        </div>
                                        <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                            <label style={{  marginBottom: '5px' }}>Frequency</label>
                                           
                                          
                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>
                                                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                    </div>
                                                )}
                                                menuListColor={Colors.Dark[0]}
                                                options={consumptionData}
                                                value={consumptionData.filter(
                                                    (v) => v.label === inputData?.weatherPriceFrequency
                                                )}
                                                placeholder={"Every minute"}
                                                _handleSelect={(e) =>
                                                    _handleChangeInputData(e.label, "weatherPriceFrequency")
                                                }
                                            />
                                           
                                        </div>
                                        <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                            <label style={{  marginBottom: '5px' }}>Horizon</label>

                                            <Selector
                                                getOptionLabel={(e) => (
                                                    <div style={{ display: "flex", textAlign: "left" }}>
                                                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                    </div>
                                                )}
                                                menuListColor={Colors.Dark[0]}
                                                options={consumptionData}
                                                value={consumptionData.filter(
                                                    (v) => v.label === inputData?.weatherPriceHorizon
                                                )}
                                                placeholder={"365 day"}
                                                _handleSelect={(e) =>
                                                    _handleChangeInputData(e.label, "weatherPriceHorizon")
                                                }
                                            />
                                        </div>
                                        <div className="">
                                        <MyFileInput
                                            text=""
                                            id={"weatherFile"}
                                            theme={theme}
                                            fontSize={"15px"}
                                            toolTip={true}
                                        file={inputData?.weatherFile}
                                        onChange={(e) =>
                                          _handleChangeInputData(e.target.files[0], "weatherFile")
                                        }
                                        />
                                    </div>
                                    <MyButton
                                        text={'Upload'}
                                        id={"button"}
                                        custom={'custom'}
                                        customColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg}
                                        customHoverColor={theme === "dark" ? Colors.dark.softGreenBg : Colors.light.softGreenBg} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    })
);
