import { Fragment } from "react";
import { Colors,invertDark,invertSolidDark } from "../Config/colors.config";
// import "../App.css";
import { inject, observer } from "mobx-react";
// import "../config"

export const MyInput = inject("store")(
  observer((props) => {
    const { theme } = props.store.common;
    const {
      isTextArea,
      style,
      placeHolder,
      disabled,
      required,
      type,
      id,
      height,
      width,
      onChange,
      value,
      maxLength,
      pattern,
      myRef,
      customColor,
      _handleView,
      autoComplete,
      visible,
      label,
      min,
      max,
      onKeyDown,
      background,
      color
    } = props;
    // const theme = localStorage.getItem("theme");
    const defaultStyle = {
      width: width === undefined ? "100%" : width,
      // background:theme==='dark'&&Colors.darkBlue,
      color: color ? color : invertSolidDark(theme),
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: 14,
      height: height,
      boxShadow: "none",
      shapeOutline: "none",
      outline: "none",
      border: `1px solid ${theme === 'dark' ? Colors.Dark[2] : Colors.Dark[4]}`,
      borderRadius: 5,
      background: background ? background : Colors[theme].softGreenBg
    };
    const disabledStyle = {
      background: customColor,
    };
    // const defaultStyle1=disabled && disabledStyle
    const userStyle = style === undefined ? {} : style;

    // const __handleFocus = () => {
    //   document.getElementById(
    //     id
    //   ).style.border = `2px solid ${Colors["dark"].primaryColor}`;
    // };
    // const __handleBlur = () => {
    //   document.getElementById(
    //     id
    //   ).style.border = `2px solid ${Colors["dark"].secondaryColor}`;
    // };
    // 
    return (
      <Fragment>
        {label && <label style={{ fontSize: 13, padding: '2px', color: Colors[theme].textColor }}>{label} :</label>}
        {isTextArea === undefined ? (<input
          ref={myRef}
          spellCheck="false"
          autoComplete={autoComplete}
          id={id}
          disabled={disabled}
          required={required}
          onChange={onChange}
          onKeyDown={onKeyDown}
          style={{
            ...defaultStyle,
            // ...defaultStyle1,
            ...userStyle,
          }}
          // onBlur={__handleBlur}
          pattern={pattern ? pattern : null}
          placeholder={placeHolder}
          className="form-control"
          // onFocus={__handleFocus}
          type={visible ? "text" : type}
          value={value != null ? value : ""}
          maxLength={maxLength}
          min={min}
          max={max}
        />)
          : (
            <textarea
              spellCheck="false"
              id={id}
              disabled={disabled}
              required={required}
              onChange={onChange}
              onKeyDown={onKeyDown}
              style={{
                ...defaultStyle,
                // ...defaultStyle1,
                ...userStyle,
              }}
              // onBlur={__handleBlur}
              pattern={pattern ? pattern : null}
              placeholder={placeHolder}
              className="form-control"
              // onFocus={__handleFocus}
              type={visible ? "text" : type}
              value={value != null ? value : ""}
            />
          )
        }
        {type === "password" && (
          <span
            style={{
              float: "right",
              position: "relative",
              marginTop: "-35px",
              fontSize: "18px",
              marginRight: "20px",
              cursor: "pointer",
            }}
            onClick={() => _handleView()}
          >
            <i
              className={`bi bi-eye${!visible ? "-slash" : ""
                } py-4 text-secondary`}
            />
          </span>
        )}
      </Fragment>
    );
  })
);
