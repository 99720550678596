
import { observable, action, makeObservable, runInAction } from 'mobx';
import { toJS } from 'mobx';
class Timeseries {
    selectedPageIndex = 1;
    inputData = {
    }
    inputDataSec = []


	constructor() {
		makeObservable(this, {
			selectedPageIndex: observable,
      inputData: observable,
      inputDataSec: observable,


			_handleNextIndex: action.bound,
			_handleBackIndex: action.bound,
      _handleChangeInputData: action.bound,
      _handleChangeInputDataSec: action.bound,
      handlecheck: action.bound
		});
	}

  handlecheck = (e,key) => {
    console.log(e);
    this.inputData[key] = !e
  }

  _handleChangeInputData = (e,key) => {
         this.inputData[key] = e
  }

  _handleChangeInputDataSec = (i, e, key) => {

    const updatedDataSec = [...this.inputDataSec];
    if (!updatedDataSec[i]) {
      updatedDataSec[i] = {};
    }
    if(key ==="title" || key === "assetTitle" || key === "carriesTitle"){
      updatedDataSec[i][key] = e;
    }else{
      updatedDataSec[i][key] = !e;
    }
    
    this.inputDataSec = [...updatedDataSec]
    console.log("data",toJS(this.inputDataSec));
  }
  

    _handleNextIndex = () => {
      if (this.selectedPageIndex < 13){
        this.selectedPageIndex = this.selectedPageIndex + 1
      }
       
      }
      _handleBackIndex = () => {
        if (this.selectedPageIndex > 1){
        this.selectedPageIndex = this.selectedPageIndex - 1
      }
    }

}

export default new Timeseries();
