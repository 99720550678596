import { inject, observer } from 'mobx-react';

const Home = inject('store')(
	observer((props) => {
		return (
			<div>
				<h1 className="text-primary">Home</h1>
			</div>
		);
	}),
);

export default Home;
