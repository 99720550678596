import { observable, action, makeObservable, runInAction } from 'mobx';

class Common {
	theme = 'dark';

	constructor() {
		makeObservable(this, {
			theme: observable,

			_handleTheme: action.bound,
		});
	}

	_handleTheme = (value) => {
		runInAction(() => (this.theme = value));
	};
}

export default new Common();
