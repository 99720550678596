import { inject, observer } from "mobx-react";
import * as React from "react";
import { Selector } from "../../Tools/MySelector";
// import { MyInput,Selector,MyFileInputRow } from "../../Tools";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { toJS } from "mobx";
import { Colors, invertDark, invertSolidDark } from "../../Config/colors.config";
import { MyFileInput } from "../../Tools/MyFileInput";
import { MyButton } from "../../Tools/MyButton";
import HelpIcon from '@mui/icons-material/Help';
import { MyInput } from "../../Tools/MyInput";
import {
  IconButton,
  Collapse,
  Box,
  Typography,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Checkbox from '@mui/material/Checkbox';




// import DownloadIcon from '@mui/icons-material/Download';
// import SelectLocationModal from "../../Components/SpecialOneTime/SelectLocationModal";
// import "../../config"

export const Page12 = inject("store")(
  observer((props) => {
    const { theme } = props.store.common;
    const { _handleChangeInputData, inputData, handlecheck,inputDataSec,_handleChangeInputDataSec } = props.store.timeseries;
    // const { setInputs, inputs } = props.store.building;
    // const { _handleChangeInputData, preliminaryData } =
    //   props.store.newTargetSetting;

   const tempData = ["Add New Figure"]



    return (
      <div className="mx-3 my-3">
        { tempData.map( (v,i) => {
         return (
<>
        <div
          className="d-flex px-3 py-3 w-100 mb-3"
          style={{
            backgroundColor: "#38B67A",
            borderRadius: 5,
            marginLeft: "5px",
          }}
        >
          <label style={{ color: "#fff"}}>{v}</label>
          <div
            className="d-flex align-items-center bg-light justify-content-center ms-auto"
            style={{ borderRadius: "50%", width: 25, height: 25 }}
            onClick={() => handlecheck(inputData.isOpen ? inputData.isOpen : false, "isOpen")}>
            <IconButton
              style={{}}
              aria-label="expand row"
              size="small"
            // onClick={()=>handlecheck(inputData.isOpen ? inputData.isOpen : false, "isOpen")}
            >
              {!inputData?.isOpen ? (
                <AddIcon
                  style={{ color: "green", fontWeight: "bold" }}
                />
              ) : (
                <RemoveIcon
                  style={{ color: "green", fontWeight: "bold" }}
                />
              )}
            </IconButton>
          </div>

        </div>
        <Collapse in={inputData.isOpen}>
          <div className="col-12 py-2" style={{}}>

            <div className="col-xl-4 col-lg-4 col-md-4 col-4 d-flex" style={{ justifyContent: 'space-between' }}>
              <label style={{marginTop: "12px", color: invertSolidDark(theme), marginBottom: '5px', paddingLeft: 20}}>Title</label>
              <MyInput
                width="250px"
                background="#fff"
                color="#000"
                type="text"
                value={inputDataSec[i]?.carriesTitle || ''} // Set an empty string if inputData?.capacity is undefined
                onChange={(e) => _handleChangeInputDataSec(i,e.target.value, "carriesTitle")}
              />
            </div>

            <div className="col-12">
         
              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-3 col-3">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px" }}
                    >
                      Net Electricity (net metered value)
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-9" style={{}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                            textAlign:'center'
                          }}
                        >
                          <span className="" style={{} } >Optimized solution scenario</span>
                          <Checkbox
                            checked={inputDataSec[i]?.netElecSolution ? inputDataSec[i]?.netElecSolution : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.netElecSolution ? inputDataSec[i]?.netElecSolution : false, "netElecSolution")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                            textAlign:'center'
                          }}
                        >
                          <span className="" style={{}} >Baseline scenario</span>
                          <Checkbox
                            checked={inputDataSec[i]?.netElecBaseline ? inputDataSec[i]?.netElecBaseline : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.netElecBaseline ? inputDataSec[i]?.netElecBaseline : false, "netElecBaseline")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-3 col-3">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px" }}
                    >
                      Natural Gas
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-9" style={{}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                  
                          <Checkbox
                            checked={inputDataSec[i]?.naturalGasSolution ? inputDataSec[i]?.naturalGasSolution : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.naturalGasSolution ? inputDataSec[i]?.naturalGasSolution : false, "naturalGasSolution")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                             checked={inputDataSec[i]?.naturalGasBaseline ? inputDataSec[i]?.naturalGasBaseline : false}
                             onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.naturalGasBaseline ? inputDataSec[i]?.naturalGasBaseline : false, "naturalGasBaseline")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-3 col-3">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px" }}
                    >
                      Net H2
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-9" style={{}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                  
                          <Checkbox
                            checked={inputDataSec[i]?.netH2Solution ? inputDataSec[i]?.netH2Solution : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.netH2Solution ? inputDataSec[i]?.netH2Solution : false, "netH2Solution")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputDataSec[i]?.netH2Baseline ? inputDataSec[i]?.netH2Baseline : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.netH2Baseline ? inputDataSec[i]?.netH2Baseline : false, "netH2Baseline")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-3 col-3">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px" }}
                    >
                      Net Cooling
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-9" style={{}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                  
                          <Checkbox
                           checked={inputDataSec[i]?.netCoolingSolution ? inputDataSec[i]?.netCoolingSolution : false}
                           onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.netCoolingSolution ? inputDataSec[i]?.netCoolingSolution : false, "netCoolingSolution")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputDataSec[i]?.netCoolingBaseline ? inputDataSec[i]?.netCoolingBaseline : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.netCoolingBaseline ? inputDataSec[i]?.netCoolingBaseline : false, "netCoolingBaseline")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-3 col-3">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px" }}
                    >
                      Solar
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-9" style={{}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                  
                          <Checkbox
                            checked={inputDataSec[i]?.solarSolution ? inputDataSec[i]?.solarSolution : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.solarSolution ? inputDataSec[i]?.solarSolution : false, "solarSolution")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputDataSec[i]?.solarBaseline ? inputDataSec[i]?.solarBaseline : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.solarBaseline ? inputDataSec[i]?.solarBaseline : false, "solarBaseline")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div
                className=" mb-3 mt-3"
                style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
              >
                <div className="col-lg-3 col-3">
                  <div className=" d-flex">
                    <div
                      className="col"
                      style={{ marginTop: "20px", marginLeft: "15px" }}
                    >
                      Wind
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-9" style={{}}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "end",
                      
                    }}
                  >
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="">
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                  
                          <Checkbox
                            checked={inputDataSec[i]?.windSolution ? inputDataSec[i]?.windSolution : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.windSolution ? inputDataSec[i]?.windSolution : false, "windSolution")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                    <div className="col-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <span
                          style={{
                            minHeight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                         
                          <Checkbox
                            checked={inputDataSec[i]?.windBaseline ? inputDataSec[i]?.windBaseline : false}
                            onChange={() => _handleChangeInputDataSec(i, inputDataSec[i]?.windBaseline ? inputDataSec[i]?.windBaseline : false, "windBaseline")}
                            sx={{
                              color: "white",
                              '&.Mui-checked': {
                                color: "green",
                              },
                            }}
                          />
                        </span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </Collapse>
        </>
         )

        })

        }
        
      </div>
      
    );
  })
);
