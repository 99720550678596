import { inject, observer } from "mobx-react";
import * as React from "react";
import { Colors, invertDark, invertSolidDark } from "../../Config/colors.config";
import HelpIcon from '@mui/icons-material/Help';
import { MyInput } from "../../Tools/MyInput";


export const Page5 = inject("store")(
  observer((props) => {
    const { theme } = props.store.common;
    const { _handleChangeInputData, inputData} = props.store.timeseries;

    return (
      <div>
        <div
          className=""
          style={{
            marginLeft: "5px",
          }}
        >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="col-lg-12 col-12" style={{}}>

                        <div
                            className=" mb-3 mt-3"
                            style={{ display: "flex", alignItems: "center",color:invertSolidDark(theme) }}
                        >
                            <div className="col-lg-2 col-2">
                                <div className=" d-flex">
                                    <div
                                        className="col d-flex"
                                        style={{ marginTop: "20px", marginLeft: "15px"}}
                                    >
                                        CHP 1:
                                        <div data-toggle="tooltip"
                                           title="CHP 1"
                                           style={{maxWidth: "fit-content"}}> 
                                        <HelpIcon
                                            style={{
                                                fill: theme === "dark" ? "#ddd" : "#1565c0",
                                                marginLeft: "4px",
                                            }}
                                        />
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-lg-10 col-10" style={{ paddingLeft: "20px" }}>
                                <div
                                    className=""
                                    style={{
                                        display: "flex",
                                        alignItems: "end",
                                        gap: "20px"
                                    }}
                                >
                                  
                                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                        <label style={{ color: invertSolidDark(theme), marginBottom: '5px' }}>Capacity</label>
                                        <MyInput
                                            type="text"
                                            value={inputData?.chpCapacity || ''} // Set an empty string if inputData?.capacity is undefined
                                            onChange={(e) => _handleChangeInputData(e.target.value, "chpCapacity")}
                                        />
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                        <label style={{ color: invertSolidDark(theme), marginBottom: '5px' }}>Area</label>
                                        <MyInput
                                            type="text"
                                            value={inputData?.chpArea || ''} // Set an empty string if inputData?.capacity is undefined
                                            onChange={(e) => _handleChangeInputData(e.target.value, "chpArea")}
                                        />
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                        <label style={{ color: invertSolidDark(theme), marginBottom: '5px' }}>Normal efficiency</label>
                                        <MyInput
                                            type="text"
                                            value={inputData?.chpNormalEffi || ''} // Set an empty string if inputData?.capacity is undefined
                                            onChange={(e) => _handleChangeInputData(e.target.value, "chpNormalEffi")}
                                        />
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-3 col-3">
                                        <label style={{ color: invertSolidDark(theme), marginBottom: '48.21px' }}>Efficiency map</label>

                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-3 col-3 ms-auto" >
                                        <label style={{ color: invertSolidDark(theme), marginBottom: '5px' }}>Annual maintainance codet</label>
                                        <MyInput
                                            type="text"
                                            value={inputData?.chpMaintainanceCost || ''} // Set an empty string if inputData?.capacity is undefined
                                            onChange={(e) => _handleChangeInputData(e.target.value, "chpMaintainanceCost")}
                                        />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    );
  })
);
