import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
// import "./index.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "mobx-react";
import Store from "./stores/Store";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <CookiesProvider>
    <BrowserRouter>
      <Provider store={Store}>
        <React.Suspense>
            <App />
        </React.Suspense>
      </Provider>
    </BrowserRouter>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
